import React, { useState, useEffect } from "react";
import "./shadow.style.scss";

export const ShadowComponent = () => {
  const [isSticky, setAsSticky] = useState(false);

  const handleScroll = (event) => {
    if (window.scrollY > 50) setAsSticky(true);
    else setAsSticky(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <div className={isSticky ? "shadow" : null}></div>;
};
