import { useEffect, useState, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import SignatureCanvas from "react-signature-canvas";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import Swal from 'sweetalert2'
import classnames from 'classnames'
import signIcon from "../../assets/feather-alt-solid.svg";
import { getSignDataAction } from "../../redux/actions/task";
import gunceldata from "../../test-json/new.json";
import axios from "../../helpers/axios";
import moment from 'moment'

import "./tasks.style.scss";
import "shepherd.js/dist/css/shepherd.css";

const TaskSign = (props) => {
  const [arrayState, setArrayState] = useState([]);
  const [name, setName] = useState('');
  const [phrase, setPhrase] = useState('IF');
  const [signAreaActive, setSignAreaActive] = useState(false);
  const [signDate, setSignDate] = useState("You haven't signed yet");
  const [clickToSignText, setClickToSignText] = useState("Click to Sign");
  const tourReducer = useSelector((state) => state.tourReducer);
  const signData = useSelector((state) => state.signData);
  const sigPad = useRef({});
  const { register, errors, handleSubmit, trigger, setError, control } = useForm();
  console.log("errors", errors);
  const location = useLocation()

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSignDataAction(props.match.params.id));
    /*trigger();
    handleSubmit(onSubmit)*/
  }, [dispatch]);
  const handleSignArea = () => {
    setClickToSignText("Signing...")
    setTimeout(function () {
      const timeElapsed = Date.now()
      const today = new Date().toJSON().slice(0, 19)
      setSignAreaActive(true)
      setSignDate("Sign Date : " + today.replace("T", " "))
    }, 1000)
  }
  const onChange = (ctid, e) => {
    const array = [...arrayState];
    const hasItem = array.some((item) => item.id === ctid);
    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        array.push({ id: ctid, value: true });
        setArrayState(array);
      } else if (!e.target.checked) {
        const index = array.findIndex((x) => x.id === ctid);
        array.splice(index, 1);
        setArrayState(array);
      }
    } else {
      if (hasItem) {
        const index = array.findIndex((x) => x.id === ctid);
        array[index] = { id: ctid, value: e.target.value.trim() };
        setArrayState(array);
        if (array[index].value === "") {
          array.splice(index, 1);
          setArrayState(array);
        }
      } else {
        array.push({ id: ctid, value: e.target.value.trim() });
        setArrayState(array);
      }
    }
  };
  const guncel = gunceldata.paragraphs;
  function isCanvasBlank(canvas) {
    const context = canvas.getContext("2d");
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !pixelBuffer.some((color) => color !== 0);
  }
  const clearForm = () => {
    sigPad.current.clear()
  };

  const fillCanvas = () => {
    var e = document.getElementById("fonts");
    var fontVal = e.value;
    var name = document.getElementById("fullName").value;
    var canvas = document.getElementsByClassName("sigCanvas")[0];
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = fontVal;
    ctx.fillText(name, 30, 100);
  };

  const pushTour = (errors, tour) => {
    Object.keys(errors).forEach((item) => {
      console.log(`tour-${errors[item].ref?.name}`);
      dispatch({
        type: "UPDATE_TOUR_VALUE",
        error: item,
        element: `.tour-${errors[item].ref?.name}`,
      });
    });
  };

  const onSubmit = async (data) => {
    if (!signAreaActive) {
      return false;
    }
    if ((Object.keys(sigPad.current).length > 0 && sigPad.current.isEmpty())) {
      document.querySelectorAll("#error-div").forEach((item) => (item.style.display = "block"));
    } else {
      trigger();
      let client2 = false;
      signData?.data?.map(function (item, i) {
        if (item.runs !== undefined && item.runs[0]?.client2 !== undefined) {
          client2 = item.runs[0]?.client2
        }
      })
      const user_no = JSON.parse(localStorage.getItem("u_data")).user_no
      const reqData = {
        name: JSON.parse(localStorage.getItem("u_data")).forename,
        user_no,
        client_2: client2,
        ...data
      }
      await axios.post(`cases/${localStorage.getItem("user")}/histories/e-sign/${props.match.params.id}`, reqData)
        .then(res => {
          Swal.fire({
            title: 'Success!',
            text: 'Form submitted, please wait...',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          setTimeout(() => {
            if (location.search.split("=")[1] == "1") {
              props.history.push("/main/tasks/1")
            } else if (location.search.split("=")[1] == "2") {
              props.history.push("/main/report-on-title/esign-only/1")
            } else if (location.search.split("=")[1] == "3") {
              props.history.push("/main/purchase-docs/fl-overview")
            } else if (location.search.split("=")[1] == "4") {
              props.history.push("/main/sale-docs/fl-overview")
            } else {
              props.history.goBack()
            }
          }, 1500)
        })
        .catch(err => {
          Swal.fire({
            title: 'Error!',
            text: 'An unexpected error has occurred. Contact the IT team',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          console.log("err", err);
        })
      console.log("data", reqData);
    }

  }

  const generateName = (item) => {
    if (item?.proclaim) {
      return `${item.name}_____${item.proclaim}`
    }
    return item?.name
  }

  if (signData.error) {
    return <div className="tasks-container">
      <div className="rt-table">
        <div className="form-content-container" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <div className="alert alert-danger" style={{ color: '#fff', fontSize: 13, padding: 10 }}>
            <span>Requested file not found. &nbsp;</span>
          </div>
        </div>
      </div>
    </div>
  }
  return (
    <form className="tasks-container e-sign-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="rt-table">
        <div className="form-content-container" style={{ marginTop: "70px", marginLeft: "90px", marginRight: "50px" }}>
          {/*//{signData?.paragraphs?.map((item, index) => (*/}
          {signData?.data[0]?.info && (
            <>
              <div className="alert-warning fixed-alert">
                This document was signed by <b>{signData.data[0].info.signed_by.full_name_company_name}</b> on {moment(signData.data[0].info.signed_date).format('DD-MM-YYYY HH:mm')}.
              </div>
            </>
          )}
          {signData?.data?.map((item, index) =>
          (
            <div style={{ marginBottom: "4px" }} key={index}>
              {item?.runs.length == 0 && (<div class="extra-height"></div>)}
              {
                item?.runs?.map((runItem, runIndex) => {
                  return runItem.input && runItem.inputType.toUpperCase() === "TEXTBOX" ? (
                    <div style={{ marginTop: "20px" }} className={`tour-${runItem.name}`}>
                      <input
                        type="text"
                        placeholder="Type..."
                        className="sign-input"
                        name={generateName(runItem)}
                        ref={register({ required: runItem?.isRequired })}
                        disabled={(runItem?.disabled)}
                        defaultValue={runItem?.defaultValue}
                        onChange={(e) => onChange(e.target.name, e)}
                      />
                    </div>
                  ) : runItem.input && runItem.inputType.toUpperCase() === "TEXTAREA" ? (
                    <div style={{ marginTop: "50px" }} className={`tour-${runItem.name}`}>
                      <textarea
                        placeholder="Type..."
                        className="sign-textarea"
                        name={generateName(runItem)}
                        ref={register({ required: runItem.isRequired })}
                        onChange={(e) => onChange(e.target.name, e)}
                        disabled={runItem.disabled}
                        defaultValue={runItem.defaultValue}
                      />
                    </div>
                  ) : runItem.input && runItem.inputType.toUpperCase() === "RADIO" ? (
                    <div className={`tour-${runItem.name}`}>
                      <label className="label-container">
                        {runItem.label}
                        {runItem?.defaultValue ? (
                          <input
                            value={runItem.value}
                            checked={(runItem.value.trim() === runItem.defaultValue)}
                            type="radio"
                            name={generateName(runItem)}
                            ref={register({ required: runItem.isRequired })}
                            onChange={(e) => onChange(e.target.name, e)}
                            disabled={runItem.disabled}
                          />
                        ) : (
                          <input
                            value={runItem.value}
                            type="radio"
                            name={generateName(runItem)}
                            ref={register({ required: runItem.isRequired })}
                            onChange={(e) => onChange(e.target.name, e)}
                            disabled={runItem.disabled}
                          />
                        )}

                        <span className="checkmark"></span>
                      </label>
                    </div>
                  ) : runItem.input && runItem.inputType.toUpperCase() === "CHECKBOX" ? (
                    <div className={`tour-${runItem.name}`}>
                      <label className="label-container-checkbox">
                        {runItem.label}
                        <input
                          value={runItem.value}
                          type="checkbox"
                          name={generateName(runItem)}
                          onChange={(e) => onChange(e.target.name, e)}
                          ref={register({ required: runItem.isRequired })}
                          checked={(runItem.defaultValue)}
                          disabled={runItem.disabled}
                        />
                        <span className="checkmark-checkbox" style={{ marginBottom: "20px" }}></span>
                      </label>
                    </div>
                  ) : runItem.signature ? (
                    <div class="sign-area-container">
                      {signData?.data[0]?.clients?.map((client, index) =>
                      (
                        <div className={signAreaActive || client.signed ||  (index + 1) !== JSON.parse(localStorage.getItem("u_data")).user_no ? 'sign-area sign-font_' + (index + 1) + ' active text-danger ' : 'sign-area sign-font_' + (index + 1) + ''}>
                          <span className={ !client.signed &&  (index + 1) !== JSON.parse(localStorage.getItem("u_data")).user_no ? 'notSignedClient' : ''}>{client.forename} {client.surname}</span><br />
                          <small className={ client.signed || (signAreaActive && (index + 1) === JSON.parse(localStorage.getItem("u_data")).user_no) ? 'text-success' : ''}>{ client.signed ? 'Signed on ' + moment(client.signed).format('DD-MM-YYYY HH:mm') : (index + 1) === JSON.parse(localStorage.getItem("u_data")).user_no ?  signDate : 'Has not signed yet' }</small>
                          {(index + 1) === JSON.parse(localStorage.getItem("u_data")).user_no && !signAreaActive ? (
                            <>
                              <div className="click-to-sign">
                                <button onClick={handleSignArea} type="button"><img src={signIcon} alt="sign icon" className="signIconInSignArea" /> {clickToSignText}</button>
                              </div>
                            </>
                          ) : null }

                        </div>
                      ))}
                    </div>

                  ) : runItem.table ? (
                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <table className="styled-table">
                        <thead>
                          <tr>
                            {runItem.rows[0].map((item, index) => (
                              <th key={index}>{item}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {runItem.rows.map((tableItem, tableIndex) => tableIndex !== 0 && (

                            <tr key={tableIndex}>
                              {tableItem.map((i, tableInlineIndex) => (
                                <td key={tableInlineIndex}>{i}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : runItem.client2 ? (
                    <div> </div>
                  ) : runItem.client3 ? (
                    <div> </div>
                  ) : runItem.client4 ? (
                    <div> </div>
                  ) : runItem.phrase ? (
                    <div>
                      <input
                        type="hidden"
                        name="phrase"
                        defaultValue={runItem?.phrase}
                      />
                    </div>
                  ) : runItem.content ? (
                    <span className={classnames({
                      "text-bold": runItem.bold,
                      "text-underline": runItem.underline,
                      "text-italic": runItem.italic
                    })}>{runItem.content}</span>

                  ) : (<div class="extra-height"></div>)
                })
              }
              {/* {data ? (
                  <>

                    {data.paragraph.elements.textRun.inputType === null && !data.paragraph.elements.textRun.signature ? (
                      <>
                        {
                          data.paragraph.elements.textRun.client2 !== "true" ? (
                            data.paragraph.elements.textRun.phrase === null ? (
                              <div style={{ marginTop: "30px" }}>
                                <p>{data.paragraph.elements.textRun.content}</p>
                              </div>
                            ) : null
                          ) : null
                        }
                        {data.paragraph.elements.textRun.client2 ?
                          <input type="hidden" name="client_2" value={true} ref={register({})} />
                          : null}
                        {data.paragraph.elements.textRun.client3 ? (
                          <input type="hidden" name="client_3" value={true} ref={register({})} />
                        ) : null}
                        {data.paragraph.elements.textRun.client4 ? (
                          <input type="hidden" name="client_3" value={true} ref={register({})} />
                        ) : null}
                        {data.paragraph.elements.textRun.phrase !== null ? (
                          <input type="hidden" name="phrase" value={data.paragraph.elements.textRun.phrase} ref={register({})} />
                        ) : null}
                      </>

                    ) : null} 
                {data.list ? (
                  <div style={{ marginTop: "20px" }}>
                    <li>{data.list.content.paragraph.elements.listItemRun.content}</li>
                  </div>
                ) : null} */}
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "center", height: "70px" }}>
            <ShepherdTour
              steps={tourReducer.data}
              tourOptions={{
                useModalOverlay: true,
              }}
            >
              <Content pushTour={pushTour} errors={errors} tourReducer={tourReducer} signAreaActive={signAreaActive}></Content>
            </ShepherdTour>

          </div>
        </div>
      </div>
    </form>
  );
}
export default TaskSign;

const Content = ({ pushTour, errors, tourReducer, signAreaActive }) => {
  console.log(signAreaActive)
  const tour = useContext(ShepherdTourContext);
  const dispatch = useDispatch();

  useEffect(() => {
    pushTour(errors, tour);
  }, [errors]);

  useEffect(() => {
    if (tourReducer.fetched) {
      tour.start();
    }
  }, [tourReducer.fetched]);
  return (
    <div>
      {signAreaActive ? (
        <>
          <input id="submitButton" type="submit" style={{ height: "30px" }} value="Submit"
            onClick={() => {
              dispatch({ type: "RESET_STORE" });
              console.log(errors);
            }} />
        </>
      ) : (
        <>
          <input id="submitButton" type="submit" style={{ height: "30px" }} value="Submit"
            className="disabledButton"
            disabled={true} />
        </>
      )}

    </div>
  );
};