import axios from "../../../helpers/axios"

export const taskCountAction = () => {

    return async (dispatch) => {
        try {
            dispatch({
                type: 'TASK_COUNT_PENDING',
            })
            const res = await axios.get(`/logged-user?user_no=${JSON.parse(localStorage.getItem("u_data")).user_no}`)
            dispatch({
                type: 'TASK_COUNT_DONE',
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: 'TASK_COUNT_ERROR',
                payload: error
            })
        }
    }
}