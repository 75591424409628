import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { useTable, usePagination, useSortBy, useRowState } from 'react-table'
import { useGlobalFilter } from 'react-table'
import no_result_found from '../../../assets/no_result_found.png'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useSticky } from 'react-table-sticky'
import BounceLoader from 'react-spinners/BounceLoader'
import { css } from '@emotion/core'
import '../../tasks/tasks.style.scss'
import { withRouter } from 'react-router-dom'
import { HTTP_URL, HTTPS_URL } from '../../config'
import { getRotSignAction } from '../../../redux/actions/rotEs/getRotSignAction'
import moment from 'moment'
import ArrowLeft from '../../../assets/arrow-left.svg'
import { useHistory } from 'react-router'

import { defaultColumn } from './columns/default'

import axios from '../../../helpers/axios'
const Styles = styled.div`
  table {
    width: calc(100% - 40px);
    border-spacing: 0;
    border: 1px solid var(--table-border-color);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid var(--table-border-color);
      color: var(--font-color-active);
      font-weight: 300;
      :last-child {
        border-right: 0;
      }
    }
    button {
      width: 150px;
      background: #7fad40;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 5px 20px 5px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--light-background-color);
      transition: background-color 0.2s;
    }
    .row:nth-of-type(even) {
      background-color: var(--collapsible-trigger-color);
      transition: background-color 0.2s;
    }
    &.stickyTable {
      margin: 20px;
      .row:nth-of-type(odd) {
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--light-background-color);
        }
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--collapsible-trigger-color);
        }
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background: var(--light-background-color);
      }
    }
    .pagination {
      display: inline;
    }
  }
`
const EsignOnly = (props, theme) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const router = useHistory()

  const rotSign = useSelector((state) => state.RotSign)
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `
  const createSearchUrl = (type, inPage = 0) => {
    let url = ''
    if (type === 'query') {
      url = `?page=${props.match.params.page}`
    } else if (type === 'url') {
      url = `${inPage}`
    }

    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split('&')
    locationSearch.forEach((item) => {
      const keyValue = item.split('=')
      if (keyValue[1] !== undefined) {
        url = `${url}&${keyValue[0]}=${keyValue[1]}`
      }
    })

    return url
  }

  const fileDownload = async (historyId, downloadCode, ref) => {
    setLoading(true)
    const username = localStorage.getItem('user')
    axios(
      `${HTTPS_URL}/cases/${ref}/histories/e-sign/${historyId}/download?download_code=${downloadCode}`,
      {
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    ).then(async (response) => {
      console.log(response.data)
      setLoading(false)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${historyId}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
  }

  useEffect(() => {
    dispatch(getRotSignAction(props.page, createSearchUrl('query')))
  }, [])

  const columns = React.useMemo(() => defaultColumn(loading, fileDownload), [])

  const tableDAta = useTable(
    {
      columns,
      data: rotSign.historyData,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    useSticky,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    data,
    gotoPage,
    setPageSize,
    pageSize,
    state,
    setGlobalFilter
    // totalRecords,
  } = tableDAta
  console.log('page', page)
  let recordsInfoText = `Showing ${1}-${page.length} of ${data.length} entries`
  const { globalFilter } = state
  return (
    <div>
      {rotSign.fetching ? (
        <BounceLoader color={'#7FAD40'} loading={true} css={override} size={150} />
      ) : null}
      {rotSign.fetched && rotSign.fetching === false ? (
        <div id="tasks-container" className="tasks-container">
          {/* Back */}
          <button className="backButton" onClick={() => router.goBack()}>
            <img src={ArrowLeft} alt="icon" />
            Back
          </button>
          <Styles>
            <div className="rt-table">
              <div className="heading-container">
                <span className="heading">PART C: Don’t Print, e-Sign Only</span>
              </div>
              <table {...getTableProps()} className="table stickyTable">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                  })}
                  {page.length === 0 ? (
                    <tr>
                      <td colspan="4">
                        <div class="not-result no-border">
                          <img src={no_result_found} className="icon" height="150" />
                          <h3>Not Result</h3>
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            <b></b>
          </Styles>
        </div>
      ) : null}
    </div>
  )
}
export default EsignOnly
