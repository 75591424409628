import axios from "../../../helpers/axios"

export const getSignDataAction = (historyID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_SIGN_DATA_PENDING'
            })
            const res = await axios.get(`/cases/${localStorage.getItem('user')}/histories/e-sign/${historyID}`);
            dispatch({
                type: 'GET_SIGN_DATA_DONE',
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: 'GET_SIGN_DATA_ERROR'
            })
        }
    }
}