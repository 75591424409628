import React, { useEffect, useState } from "react";
import "./search-field.style.scss";

export const SearchField = ({ page, path, searchText, searchStartDate, searchEndDate, searchSigned }) => {
  const [text, setText] = useState(searchText);
  const [startDate, setStartDate] = useState(searchStartDate);
  const [endDate, setEndDate] = useState(searchEndDate);

  const handle = (signed) => {
    let initUrl = `${page}?`
    if (text !== "") {
      initUrl = `${initUrl}&search=${text}`
    }
    if (startDate !== "") {
      initUrl = `${initUrl}&start_date=${startDate}`
    }
    if (endDate !== "") {
      initUrl = `${initUrl}&end_date=${endDate}`
    }
    if (signed !== "") {
      initUrl = `${initUrl}&signed=${signed}`
    }
    window.location.href = `${path}${initUrl}`
  }

  return (
    <div className="search-container">
      <div className="searchBox">
        <label>Find (i.e. details, number etc.)</label>
        <input value={text} type="text" placeholder="Find " onChange={(e) => setText(e.target.value)} />
      </div>
      <div className="buttonBox">
        <button onClick={handle}>
          Find
        </button>
      </div>
      <Selects searchSigned={searchSigned} handle={handle} />
    </div>
  );
};

const Selects = ({ handle, searchSigned }) => {

  return (
    <div className="searchBox">
      <label>Signed / Not Signed</label>
      <select onChange={(e) => {
        handle(e.target.value)
      }}>
        <option value="" selected={(searchSigned === "")}>All</option>
        <option value="signed" selected={(searchSigned === "signed")}>Signed</option>
        <option value="not_signed" selected={(searchSigned === "not_signed")}>Not Signed</option>
      </select>
    </div>
  );
}