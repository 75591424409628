export const IsLoggedIn = () => {
    return async (dispatch) => {
        try {
            const access_token = localStorage.getItem('token');
            if (access_token) {
                const user = JSON.parse(localStorage.getItem('user'))
                dispatch({
                    type: "LOGIN_USER_DONE",
                    payload: { access_token, user }
                })
            }
        } catch (error) {
            dispatch({
                type: "LOGIN_USER_ERROR",
                payload: error
            })
        }
    }
}