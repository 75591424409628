import axios from "../../../helpers/axios";
export const getRotSignAction = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_ROT_SIGN_PENDING'
            })
            const username = localStorage.getItem('user');
            const res = await axios.get(`/cases/${username}/histories/rot-es?user_no=${JSON.parse(localStorage.getItem("u_data")).user_no}`);
            console.log(res)
            dispatch({
                type: 'GET_ROT_SIGN_DONE',
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: 'GET_ROT_SIGN_ERROR',
                payload: error
            })
        }
    }
}