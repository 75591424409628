import React, { useState, useEffect } from "react";
import photo from "../../assets/blank.png";
import phoneIcon from "../../assets/contact-phone.svg";
import emailIcon from "../../assets/contact-mail.svg";
import locationIcon from "../../assets/contact-pin.svg";
import BounceLoader from "react-spinners/BounceLoader";
import ClipLoader from "react-spinners/ClipLoader";
import "./contact.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCasesAction } from "../../redux/actions/overview/getCasesAction";
import { css } from "@emotion/core";

export const Contact = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.cases);
  useEffect(() => {
    dispatch(getCasesAction());
  }, []);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  console.log("contact cases", cases);
  return (
    <>
      {cases.fetching ? <BounceLoader color={"#7FAD40"} loading={true} css={override} size={150} /> : null}
      {cases.fetched && cases.fetching === false ? (
        <div className="contact">
          <div className="card">
            <h4>Contact Your Case Manager</h4>
            <div className="horizontal-container">
              <img src={photo} className="photo" alt="case manager" />
              <div>
                <p>{cases.data.fee_earner.description}</p>
                <p>Conveyancing Expert</p>
                <div className="horizontal-container">
                  <img src={phoneIcon} className="icon" alt="contact icon" />
                  <a className="contact-link" target="_blank" href="https://avrillo.co.uk/legal-surgery/">Book Call Surgery</a>
                </div>
                <div className="horizontal-container">
                  <img src={emailIcon} className="icon" alt="email icon" />
                  <a className="contact-link" href={`mailto:${cases.data.fee_earner.email}`}>{cases.data.fee_earner.email}</a>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="card">
            <h4>Avrillo Conveyancing</h4>
            <div>
              <div className="horizontal-container">
                <img src={phoneIcon} className="icon" alt="contact icon" />
                <a className="contact-link" href="tel:020 8370 3877">020 8370 3877</a>
              </div>
              <div className="horizontal-container">
                <img src={emailIcon} className="icon" alt="email icon" />
                <a className="contact-link" href="mailto:clientservices@avrillo.co.uk">clientservices@avrillo.co.uk</a>
              </div>
              <div className="horizontal-container">
                <img src={locationIcon} className="icon" alt="location icon" />
                <p>758 Great Cambridge Road, Enfield, London, EN1 3GN</p>
              </div>
              <a href="http://www.avrillo.co.uk" target="_blank">www.avrillo.co.uk</a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
