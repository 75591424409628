const initialState = {
    fetching: false,
    fetched: false,
    data: [],
    error: false,
}

export const getRotSignDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROT_SIGN_DATA_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_ROT_SIGN_DATA_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload
            }
        case 'GET_ROT_SIGN_DATA_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}