import React, { useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import loginImg from "../../assets/login-desktop-bg.jpg";
import logoLight from "../../assets/logo.png";
import userIcon from "../../assets/user.png";
import passIcon from "../../assets/password.png";
import eye from "../../assets/eye.png";
import eye_hide from "../../assets/eye-hide.png";
import ClipLoader from "react-spinners/BounceLoader";
import { HTTP_URL } from "../config";
import "./login.style.scss";
import { loginAction } from "../../redux/actions/login";
import { useDispatch, useSelector } from "react-redux";
const Login = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  //username 172449
  //password q3bu0snx
  const [showPwd, setShowPwd] = useState(false);
  const [username, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [error_username, setErrorUsername] = useState(false);
  const [error_password, setErrorPassword] = useState(false);
  const toggleShowPwd = () => {
    setShowPwd(!showPwd);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setErrorUsername(false);
    setErrorPassword(false);
    const data = {
      grant_type: "password",
      client_id: 2,
      client_secret: "LCZYXIly3BAu95wIqCfFSbEN8OeAsHyF0hCnT1b1",
      username: username,
      password: password,
      scope: "conveyancing_case",
    };
    if(!data.username){
      setErrorUsername(true);
    }
    if(!data.password){
      setErrorPassword(true);
    }
    if(!username || !password){
      return false;
    } else {
      dispatch(loginAction(data));
    }
  };

  return (
    <div className="login">
      <div className="img-container">
        <img src={loginImg} alt="login" />
      </div>
      <div className="login-container">
        <div className="login-form">
          <img src={logoLight} alt="logo" className="login-logo" />
          <h2>Login to AVRillo's World</h2>
          <div>
            <form className="form" onSubmit={() => onSubmit()}>
              <span className={['error-message' && error_username || error_password ? 'error-message' : 'hidden']}>Please fill in all fields and try again</span>
              <div className="inputs">
                <div className="searchBox">
                  <input type="text" name="username" className={ error_username ? 'error-input' : '' } placeholder="Case Reference Number" onChange={(e) => setUser(e.target.value)} />
                  <img className="searchIcon" alt="username icon" src={userIcon} />
                </div>
                <div className="searchBox">
                  <input type={showPwd ? "text" : "password"}  className={ error_password ? 'error-input' : '' } placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                  <img className="searchIcon" alt="password icon" src={passIcon} />
                  <img title={showPwd ? 'Hide Password' : 'Show Password'} className="eye_hide_show" alt="eye icon" src={showPwd ? eye_hide : eye} onClick={toggleShowPwd} />
                </div>
              </div>
              {auth.error ? <p className="login-fail">LOGIN ERROR</p> : null}
              {auth.fetching ? (
                <div className="loginLoaderContainer">
                  <ClipLoader color={"#7FAD40"} loading={true} size={30} />
                </div>
              ) : (
                <button className="login-btn" type="submit" onClick={(e) => onSubmit(e)}>
                  LOGIN
                </button>
              )}
              {auth.done ? <Redirect to="/main/overview" /> : null}
              <p>Please use your case reference number and your password on your first letters/emails.</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Login);
