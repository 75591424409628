const initialState = {
    fetching: false,
    fetched: false,
    steps: {},
    to_exchange: [],
    after_exchange: [],
    salePercent: 0,
    saleStepNumber: 0,
    error: null
}

export const getSaleStepReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SALE_STEPS_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_SALE_STEPS_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                steps: action.payload,
                to_exchange: action.payload.to_exchange,
                after_exchange: action.payload.after_exchange,
                salePercent: Math.round((action.payload.to_exchange.length * action.payload.to_exchange_percentage +
                    action.payload.after_exchange.length * action.payload.after_exchange_percentage) /
                    (action.payload.to_exchange.length + action.payload.after_exchange.length)),
                saleStepNumber: Math.round(
                    action.payload.to_exchange.length * action.payload.to_exchange_percentage +
                    action.payload.after_exchange.length * action.payload.after_exchange_percentage
                ) / 100
            }
        case 'GET_SALE_STEPS_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}