import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy, useRowState } from "react-table";
import { Link } from "react-router-dom";
import { useGlobalFilter } from "react-table";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import no_result_found from "../../assets/no_result_found.png";
import how_to_1 from "../../assets/how-to-sign-contact.jpg";
import how_to_2 from "../../assets/how-to-sign-tr1.jpg";
import how_to_3 from "../../assets/how-to-sign-deed-of-covenant.jpg";
import how_to_4 from "../../assets/how-to-sign-mortgage-deed.jpg";
import Paginate from "react-js-pagination";
import BounceLoader from "react-spinners/BounceLoader";
import {Buffer} from 'buffer';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import dataMock from "./data";
import Swal from 'sweetalert2'
import openIcon from "../../assets/open.svg";
import { HTTPS_URL } from "../config";
import "./fl-documents-table.style.scss";
//import cheerio from "cheerio";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getHistoriesAction, getHistoryAction } from "../../redux/actions/history";
import { getFlsAction, getFlAction } from "../../redux/actions/fl";
import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import {
  Menu,
  MenuItem,
  MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
const Styles = styled.div`
  table {
    width: calc(100% - 40px);
    border-spacing: 0;
    border: 1px solid var(--table-border-color);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid var(--table-border-color);
      color: var(--font-color-active);
      font-weight: 300;
      :last-child {
        border-right: 0;
      }
    }
    button {
      width: 150px;
      background: #7fad40;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 5px 40px 5px 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--light-background-color);
      transition: background-color 0.2s;
    }
    .row:nth-of-type(even) {
      background-color: var(--collapsible-trigger-color);
      transition: background-color 0.2s;
    }
    &.stickyTable {
      margin: 20px;
      .row:nth-of-type(odd) {
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--light-background-color);
        }
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--collapsible-trigger-color);
        }
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background: var(--light-background-color);
      }
    }
    .pagination {
      display: inline;
    }
  }
  
  .play-video{
    float: left;
    cursor: pointer
  }
  .play-video img{
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    width: 270px;
  }
  .play-video span{
    display: block;
    padding: 0 10px 10px 10px;
  }
  .play-video:hover img{
    box-shadow: 0 2px 12px rgba(0,0,0,0.3)
  }
  .play-video:hover span{
    color:#7fad40
  }
  .hr-style{
    border-color:#dedede !important;
    background:transparent;
    opacity: 0.2
  }
`;

const FlDocuments = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fls = useSelector((state) => state.fls);
  const histories = useSelector((state) => state.histories);
  const fl_page = useSelector((state) => props.page);
  const document_metter = useSelector((state) => props.document_metter);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;


  const downloadDocument = (id) => {
    setLoading(true);
    const username = localStorage.getItem("user");
    axios(`${HTTPS_URL}/cases/${username}/histories/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      console.log(response)
      let str = response.data.file;
      let buff = new Buffer(str, 'base64');
      let base64ToStringNew = buff;
      const url = window.URL.createObjectURL(new Blob([base64ToStringNew]));
      const link = document.createElement("a");
      const extension = response.data.file_format;
      if (response.data.file === "") {
        Swal.fire({
          title: 'Error',
          text: 'File not found!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        setLoading(false);
        return false;
      }
      link.href = url;
      setLoading(false);
      if (extension === "html") {
        /*const scrapingRes = await axios.get(url);
        const $ = cheerio.load(scrapingRes.data);
        const scrapedHtml = $.html();
        link.setAttribute("target", "_blank");
        document.getElementById("iframe").innerHTML = `${scrapedHtml}`;
        var modal = document.getElementById("letterModal");
        modal.style.display = "block";
        var span = document.getElementsByClassName("close")[0];
        span.onclick = function () {
          modal.style.display = "none";
        };
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = "none";
          }
        };*/
      } else {
        if (extension === "MAIL") {
          extension = "msg"
        }
        link.setAttribute("download", `${id}${extension}`);
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  useEffect(() => {
    let type = "WT";
    if (fl_page == "wet-sign") {
      type = "WT"
      dispatch(getFlsAction(document_metter, "first-letters", type));
    } else if (fl_page == "esign-only") {
      type = "ES"
      dispatch(getFlsAction(type));
    }

  }, [dispatch]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "posted_date_time",
        Cell: (cell) => {
          return <div>{`${moment(cell.row.original.created_at).format('DD/MM/YYYY')} - ${moment(cell.row.original.created_at).format('LT')}`}</div>;
        }
      },
      {
        Header: "Details",
        accessor: "details"
      },
      {
        Header: "Open",
        accessor: "id",
        Cell: ({ cell }) =>
          fl_page === "esign-only" ? (
            (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `/main/task-sign/${cell.row.original.case}`;
                }}
              >
                <img src={openIcon} alt="open icon" /> Sign
              </button>
            )
          ) : (
            <a href={cell.row.values.document_name} download>
              <button
                value={cell.row.values}
                onClick={() => {
                  downloadDocument(cell.row.original.case);
                }}
              >
                <img src={openIcon} alt="open icon" /> Download
              </button>
            </a>
          ),
      },
    ],
    []
  );
  const tableDAta = useTable(
    {
      columns,
      data: fls.fetched === true ? fls.historyData : dataMock,
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    useSticky,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // totalRecords,
  } = tableDAta;
  const [isOpen_1, setOpen1] = useState(false)
  const [isOpen_2, setOpen2] = useState(false)
  const [isOpen_3, setOpen3] = useState(false)
  const [isOpen_4, setOpen4] = useState(false)
  const createSearchUrl = (type, inPage = 0) => {
    let url = "";
    if (type === "query") {
      url = `?page=${props.match.params.page}`
    } else if (type === "url") {
      url = `${inPage}?`
    }

    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split("&")
    locationSearch.forEach(item => {
      const keyValue = item.split("=")
      url = `${url}&${keyValue[0]}=${keyValue[1]}`
    })
    return url
  }
  // Render the UI for your table
  return (
    <div>
      {fls.fetching ? <BounceLoader color={"#7FAD40"} loading={true} css={override} size={150} /> : null}
      <div>
        <div id="letterModal" className="letterModal">
          <div className="letterModalContent">
            <span className="close">&times;</span>
            <div id="iframe" style={{ overflow: "auto" }}></div>
          </div>
        </div>
        {loading === true ? (
          <div className="wait-content" id="wait-content">
            <div>
              <ClipLoader color={"#04ff00"} loading={true} size={80} />
            </div>
          </div>
        ) : null}

        <Styles>
          <div className="rt-table" style={{ position: "relative" }}>
            <div className="heading-container">
              <span className="heading">
                {fl_page === 'wet-sign' ? "Wet Signs."
                  : fl_page === "esign-only" ? "E-Sign Only" : " Document for you to print, sign and post back original, by Special Delivery."}
                <br />
              </span>
            </div>
            {page.length > 0 ? (
              <table {...getTableProps()} className="table stickyTable">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr className="row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <>
                <div class="not-result">
                  <img src={no_result_found} className="icon" height="150" />
                  <h3>Not Result</h3>
                </div>
              </>
            )}
          </div>
        </Styles>
        {fl_page === "read-before" ? (
          <>
            
            <div className="bottom-controls">
              <span className="records-info">
                Showing {histories.meta.from}-{histories.meta.to + 1} of {histories.meta.total} entries
              </span>
              <div className="react-paginate">
                <Paginate
                  onChange={(e) => {
                    props.history.push(createSearchUrl("url", e.valueOf()));
                    window.location.reload();
                  }}
                  prevPageText={"⟨"}
                  firstPageText={"«"}
                  lastPageText={"»"}
                  nextPageText={"⟩"}
                  itemsCountPerPage={histories.meta.per_page}
                  itemClass={"pagination"}
                  activePage={parseInt(props.match.params.page)}
                  //getPageUrl={(e) => createSearchUrl("url", e.valueOf())}
                  getPageUrl={(e) => createSearchUrl("url", e.valueOf())}
                  totalItemsCount={histories.meta.total}
                  pageRangeDisplayed={5}
                  linkClass="linkClass"
                  activeLinkClass="activeLinkClass"
                />
              </div>
              <div className="buffer"></div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default FlDocuments