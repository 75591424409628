const initialState = {
  data: [],
  fetched: false,
};
const backBtnClass = "btn btn-sm btn-outline-primary back-btn";
const nextBtnClass = "btn btn-sm btn-primary btn-next next-btn";
const tour = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TOUR_VALUE":
      var newArray = [];
      newArray.push({
        id: action.id,
        title: "Required",
        text: "This field is required",
        attachTo: { element: action.element, on: "bottom" },
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        cancelIcon: {
          enabled: true,
        },
        buttons: [
          {
            type: "cancel",
            classes: backBtnClass,
            text: "Skip",
          },
          {
            text: "Next",
            classes: nextBtnClass,
            type: "next",
          },
        ],
        when: {
          show: function () {
            if (document.querySelector(`${action.element}`)) {
              document.querySelector(`${action.element}`).scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
            }
          },
        },
      });
      return {
        ...state,
        fetched: true,
        data: [...state.data, ...newArray],
      };

    case "RESET_STORE":
      return {
        ...state,
        data: [],
        fetched: false,
      };
    default:
      return state;
  }
};
export default tour;
