import axios from "../../../helpers/axios"

export const getSaleStepsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_SALE_STEPS_PENDING'
            })
            const res = await axios.get(`/cases/${localStorage.getItem("user")}/sale-steps`)
            dispatch({
                type: 'GET_SALE_STEPS_DONE',
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: 'GET_SALE_STEPS_ERROR',
                payload: error
            })
        }
    }
}