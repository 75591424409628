import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import { useHistory } from "react-router";
import transaction_success from "./transaction_successful.gif";
import transaction_error from "./transaction_error.gif";
import transaction_loading from "./transaction_loading.gif";
import "./after-payment.style.scss";
function AfterPayment() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(false);
  const [cancelPayment, setCancelPayment] = useState(false);
  const history = useHistory();

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    const sessionId = new URL(window.location.href).searchParams.get(
      "sessionId"
    );

    if (sessionId && localStorage.getItem("searches")) {
      document.getElementById("header").style.display = "none"
      document.getElementsByClassName("footer")[0].style.display = "none"
      document.getElementsByClassName("content-footer-container")[0].style.padding = '0'
      axios
        .patch(`${process.env.REACT_APP_BASE_URL}/payment/${sessionId}/status`, {
          searches: localStorage.getItem("searches"),
          case_ref: localStorage.getItem('user')
        })
        .then((res) => {
          setTimeout(() => {
            setLoading(false);
          },3000);
          setStatus(true);
        })
        .catch((err) => {
          setTimeout(() => {
            setLoading(false);
          },3000);
          setStatus(false);
          setCancelPayment(true);
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      },3000);
      setStatus(false);
      setCancelPayment(true);
      console.log('sdadas')
    }
  }, []);

  return (
    <>
      {loading ? (
        <>
         <div className="afterpayment">
              <div className="payment_label">
                <img src={transaction_loading} alt="" />
                <h3>Waiting for reply for payment... </h3>
                <h3 className="sub_content" style={{ marginTop: 0 }}>
                  Please Waiting
                </h3>
              </div>
            </div>
        </>
      ) : (
        <>
          {status ? (
            <div className="afterpayment">
              <div className="payment_label">
                <img src={transaction_success} alt="" />
                <h3>Payment has been successfully received</h3>
                <p className="text-muted">You can close this window.</p>
              </div>
            </div>
          ) : (
            <>
              {!cancelPayment ? (
                <div className="afterpayment">
                  <div className="payment_label">
                    <img src={transaction_error} alt="" />
                    <h3>Payment has been cancelled </h3>
                    <p className="text-muted">You can close this window.</p>
                  </div>
                </div>
              ) : (
                <BounceLoader
                  color={"#7FAD40"}
                  loading={true}
                  css={override}
                  size={150}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default AfterPayment;
