import { useState } from "react";

const ReadAgree = (props) => {
    const [secondButtonShow, setSecondButtonShow] = useState(false)

    const viewModal = (modalId) => {
        var modal = document.getElementById(modalId);
        modal.style.display = "block";
        var span = document.getElementsByClassName("close")[modalId === "myModal" ? 0 : 1];
        span.onclick = function () {
            modal.style.display = "none";
        };
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        };
    }

    const handleDownloadClick = () => {
        window.open("https://google.com", '_blank').focus();
        setSecondButtonShow(true)
    }

    return (
        <div>
            <button
                onClick={(e) => { viewModal("myModal") }}
            >Read & Agree</button>

            <div id="myModal" class="modal">
                <div class="modal-content white" style={{ height: "auto" }}>
                    <div className="modal-header"><span class="close">&times;</span></div>
                    <div className="modal-body">
                        <button onClick={handleDownloadClick}>
                            Download
                        </button>
                        {
                            secondButtonShow && (
                                <button style={{ marginTop: 15 }}>
                                    Second Button
                                </button>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReadAgree