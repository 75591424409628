import React from "react";
import moment from "moment";
import "./info-container.style.scss";
import houseIcon from "../../assets/house.svg";
export const InfoContainer = (props) => {
  moment.locale("en-GB");
  return (
    <div>
      <div className="address">
        <img src={houseIcon} alt="" />
        <div className="info">
          <p style={{ fontWeight: "700" }}>{props.address} ({props.isPurchase ? props.data.property_purchase_freehold_leasehold : props.data.property_sale_freehold_leasehold })</p>
          <p>Start Date: {moment(props.caseStartDate).format("D MMMM YYYY")} / {moment(props.caseStartDate, "YYYYMMDD").fromNow()} </p>
          <p>
            UK Average Estimated Completion Date:{"\t"}
            {moment(props.estimatedComplete).format("D MMMM YYYY")}
          </p>
        </div>
      </div>

      <div className="stages-steps-container">
        <strong>{props.isPurchase === true ? "Purchase" : "Sale"} Stages and Steps</strong>
        <div className="color-info">
          <span className="color-code">
            <div className="green-dot"></div> Completed
          </span>
          <span className="color-code">
            <div className="orange-dot"></div> Partially Completed
          </span>
          <span className="color-code">
            <div className="red-dot"></div> Not Completed
          </span>
        </div>
      </div>
    </div>
  );
};
