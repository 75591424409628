import axios from "../../../helpers/axios"

export const getCasesAction = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_CASES_PENDING'
            })
            const res = await axios.get(`/cases/${localStorage.getItem("user")}`)

            dispatch({
                type: 'GET_CASES_DONE',
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: 'GET_CASES_ERROR',
                payload: error
            })
        }
    }
}