import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import folderIcon from "../../assets/folder.svg";
import darkThemeButtonIcon from "../../assets/mode-dark.svg";
import lightThemeButtonIcon from "../../assets/mode-light.svg";
import "./side-menu.style.scss";
import { loggedUserAction, logoutAction } from "../../redux/actions/login";

const SideMenu = ({ menuOpen, closeMenu, closeBtn, setTheme, setThemeColors, theme, history }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.loggedUser);
  const [matter_type, setMatterType] = useState(false);
  const [payment, setPayment] = useState(false);
  const taskCount = useSelector((state) => state.taskCount);
  console.log("taskCount", taskCount);
  const logout = () => {
    dispatch(logoutAction());
    history.push("/");
  };
  useEffect(() => {
    dispatch(loggedUserAction);
  }, []);
  console.log("sideloggeduser", loggedUser);
  useEffect(() => {
    if (taskCount.fetched) {
      setMatterType(taskCount.data.matter_type)
      if (taskCount.data.payment) {
        setPayment(taskCount.data.payment);
      }
    }
  }, [taskCount])
  return (
    <div className={menuOpen ? "sideMenu active" : "sideMenu"}>
      <div className="sideLinksContainer">
        <button className="closeBtn" onClick={closeMenu}>
          <img src={closeBtn} alt="close" />
        </button>
        <Link to="/main/overview" className="headerLink">
          MILESTONES
        </Link>
        {matter_type && (matter_type === 'P' || matter_type === 'B') && (
          <>
            <div className="headerText">
              DOCUMENTS TO READ/SIGN - PURCHASE
            </div>
            <Link to="/main/purchase-docs/fl-overview" className="headerLink ml-2">
              ⟩ First Letters
            </Link>
            <Link to="/main/purchase-docs/rot-overview" className="headerLink ml-2">
              ⟩ Report on Title
            </Link>
            <div className="spacer-seperator"></div>
          </>
        )}
        {matter_type && (matter_type === 'S' || matter_type === 'B') && (
          <>
            <div className="headerText">
              DOCUMENTS TO READ/SIGN - SALE
            </div>
            <Link to="/main/sale-docs/fl-overview" className="headerLink ml-2">
              ⟩ First Letters
            </Link>

          </>
        )}
        <Link to="/main/case-history/1" className="headerLink">
          case history
        </Link>
        {payment && (
          <Link to="/main/payment" className="headerLink">
            PAYMENT
          </Link>
        )}
      </div>
      {loggedUser.fetched && (
        <>
          <div className="loginContainer-mobile">
            <div className="userInfo">
              <div className="userName">
                {loggedUser.data.forename} {loggedUser.data.surname}
              </div>
              <div className="folderId">
                <img src={folderIcon} alt="folder icon" />
                <p>{localStorage.getItem("user")}</p>
              </div>
              <div className="logoutBtn">
                <button
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div className="themeBtn">
            <button
              onClick={() => {
                if (theme === "dark") {
                  setTheme("light");
                  setThemeColors("light");
                  localStorage.setItem("theme", "light");
                } else {
                  setTheme("dark");
                  setThemeColors("dark");
                  localStorage.setItem("theme", "dark");
                }
              }}
            >
              <img src={theme === "light" ? darkThemeButtonIcon : lightThemeButtonIcon} alt="change theme" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(SideMenu);
