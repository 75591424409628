import moment from 'moment'
import classnames from 'classnames'
import signIcon from '../../../../assets/sign.svg'
import SignatureCanvas from 'react-signature-canvas'

export const defaultColumn = (loading, fileDownload, matter_type) => [
  { Header: 'Document', accessor: 'details' },
  {
    Header: 'Created Date',
    accessor: (d) => {
      return moment(d.posted_date_time).format('DD-MM-YYYY HH:mm')
    }
  },
  {
    Header: 'Signed Date',
    accessor: (d) => {
      let clientsRow = ''
      console.log('client_count', d.client_count)
      console.log('signatures', d)
      for (let i = 1; i <= d.client_count; i++) {
        const signedUser = d.signatures.filter((item) => item.signed_by === i)
        console.log('signed', signedUser)
        clientsRow = (
          <div>
            {clientsRow}{' '}
            <div
              className={classnames({
                'not-signed': signedUser.length <= 0,
                signed: signedUser.length > 0
              })}
            >
              Client {i}:{' '}
              {signedUser.length > 0
                ? moment(signedUser[0].created_at).format('DD-MM-YYYY HH:mm')
                : 'Not Signed'}{' '}
            </div>
          </div>
        )
      }

      return clientsRow
    }
  },
  {
    Header: 'View & Sign',
    accessor: (d) => {
      const userNo = JSON.parse(localStorage.getItem('u_data')).user_no
      let signedUser = []
      let signOrder = 0
      for (let i = 1; i <= d.client_count; i++) {
        signedUser = d.signatures.filter((item) => item.signed_by === userNo)
        signOrder = d.signatures.filter((item) => item.signed_by === userNo && item.pdf_ready)
      }
      console.log("signedUser", signedUser)
      if (signedUser.length === 0) {
        return (
          <button
            className='sign-button'
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `/main/task-sign/${d.id}?active=${matter_type == 'PURCHASE' ? 3 : 4}`;
            }}
          ><img src={signIcon} alt="sign icon" /> View & Sign</button>
        )
      } else {
        if (signOrder) {
          return (
            <button
              onClick={() => fileDownload(d.id, d.signatures[0].download_code, d.con_case_ref)}
            >
              Download
            </button>
          )
        } else {
          return <span className="pdf-progress">PDF in progress</span>
        }
      }
    }
  }
]
