import axios from "../../../helpers/axios"

export const getRotsAction = (type, searchArgs = "") => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_ROTS_PENDING'
            })
            const username = localStorage.getItem('user');
            const res = await axios.get(`/cases/${username}/histories/rot-documents/${type}${searchArgs}`);
            dispatch({
                type: 'GET_ROTS_DONE',
                payload: res.data
            })
            console.log('actions', res.data)
        } catch (error) {
            dispatch({
                type: 'GET_ROTS_ERROR',
                payload: error
            })
        }
    }
}