import axios from "../../../helpers/axios"

export const getHistoriesAction = (searchArgs) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_HISTORIES_PENDING'
            })
            const username = localStorage.getItem('user');
            const res = await axios.get(`/cases/${username}/histories${searchArgs}`);
            dispatch({
                type: 'GET_HISTORIES_DONE',
                payload: res.data
            })
            console.log('actions', res.data)
        } catch (error) {
            dispatch({
                type: 'GET_HISTORIES_ERROR',
                payload: error
            })
        }
    }
}