import axios from "axios";
import { store } from "../redux/store";
const token = window.localStorage.getItem('token');
const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: token ? `Bearer ${token}` : ''
    }
});

axiosInstance.interceptors.request.use(req => {
    const { auth } = store.getState();
    if (auth.token) {
        req.headers.Authorization = `Bearer ${auth.token}`
    }
    return req;
})

export default axiosInstance;