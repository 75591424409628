import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Sticky from "react-stickynode";
import { ShadowComponent } from "./shadow.component";
// import { StickyContainer, Sticky } from "react-sticky";
import "./progress-bar.style.scss";

export const ProgressBar = ({ Percent, StepsCompleteNumber, TotalStep, isPurchase }) => {
  const rotate = keyframes`
  from {
    width: 0%;
  }

  to {
    width: ${Percent}%;
  }
`;
  const ProgressDone = styled.div`
    background: #7fad40;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    left: 0;
    animation: ${rotate} 1s forwards;
  `;

  return (
    <Sticky enabled={true} top={95} bottomBoundary={1200}>
      <div className="progress">
        <div className="process-container">
          <div className="process-bar">
            <ProgressDone className="progressDone">
              <div className="arrow"></div>
            </ProgressDone>
          </div>
          <span className="complete-percent">
            {Percent}
            <span className="percent-symbol">%</span>{" "}
          </span>
        </div>
        <p className="process-text">
          <strong style={{ fontWeight: "700" }}>{isPurchase ? "Purchase" : "Sale"} Process</strong> {StepsCompleteNumber} of {isPurchase ? "Purchase" : "Sale"}{" "}
          {TotalStep} Steps Completed (to Exchange)
        </p>
      </div>
      <ShadowComponent />
    </Sticky>
  );
};
