const initialState = {
    fetching: false,
    fetched: false,
    data: [],
    meta: {},
    error: false
}
export const getFlsReducer = (state = initialState, action) => {
    console.log("getFlsReducer",action)
    switch (action.type) {
        case 'GET_FLS_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_FLS_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload
            }
        case 'GET_FLS_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}