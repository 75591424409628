import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import { useTable, usePagination, useSortBy, useRowState } from "react-table";
import { useGlobalFilter } from "react-table";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import Paginate from "react-js-pagination";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/core";
import dataMock from "./data";
import { SearchField } from "./SearchField/search-field.component";
import "./tasks.style.scss";
import { withRouter } from "react-router-dom";
import { HTTP_URL, HTTPS_URL } from "../config";
import { getHistoriesSignAction } from "../../redux/actions/task/getHistoriesSignAction";
import moment from "moment";

import { defaultColumn } from './columns/default'
import { drRotFilesColumn } from './columns/dr-rot-files'

import axios from '../../helpers/axios'
const Styles = styled.div`
  table {
    width: calc(100% - 40px);
    border-spacing: 0;
    border: 1px solid var(--table-border-color);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid var(--table-border-color);
      color: var(--font-color-active);
      font-weight: 300;
      :last-child {
        border-right: 0;
      }
    }
    button {
      width: 150px;
      background: #7fad40;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 5px 40px 5px 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--light-background-color);
      transition: background-color 0.2s;
    }
    .row:nth-of-type(even) {
      background-color: var(--collapsible-trigger-color);
      transition: background-color 0.2s;
    }
    &.stickyTable {
      margin: 20px;
      .row:nth-of-type(odd) {
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--light-background-color);
        }
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--collapsible-trigger-color);
        }
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background: var(--light-background-color);
      }
    }
    .pagination {
      display: inline;
    }
  }
`;
const Tasks = (props) => {
  console.log("props", props)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const historiesSign = useSelector((state) => state.historiesSign);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const createSearchUrl = (type, inPage = 0) => {
    let url = "";
    if (type === "query") {
      url = `?page=${props.match.params.page}`
    } else if (type === "url") {
      url = `${inPage}`
    }

    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split("&")
    locationSearch.forEach(item => {
      const keyValue = item.split("=")
      if (keyValue[1] !== undefined) {
        url = `${url}&${keyValue[0]}=${keyValue[1]}`
      }
    })

    return url
  }

  const getSearchInitValue = (key) => {
    let inValue = ""
    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split("&")
    locationSearch.forEach(item => {
      const keyValue = item.split("=")
      if (keyValue[0] === key) {
        inValue = keyValue[1]
      }
    })
    return inValue
  }

  const fileDownload = async (historyId, downloadCode, ref) => {
    setLoading(true)
    await axios({
      url: `https://api.avrillo.co.uk/api/cases/${ref}/histories/e-sign/${historyId}/download?download_code=${downloadCode}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      setLoading(false)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${historyId}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }

  /*   function isCanvasBlank(canvas) {
    const context = canvas.getContext("2d");
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
    return !pixelBuffer.some((color) => color !== 0);
  }
  const viewModal = (modalId) => {
    var modal = document.getElementById(modalId);
    modal.style.display = "block";
    var span = document.getElementsByClassName("close")[modalId === "myModal" ? 0 : 1];
    span.onclick = function () {
      modal.style.display = "none";
      props.history.push("/main/tasks");
    };
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
        props.history.push("/main/tasks");
      }
    };
  }; */
  /* const submitForm = (id) => {
    console.log("Submitting...");
    const data = new FormData(document.getElementById("SignForm"));
    var canvas = document.getElementsByClassName("sigCanvas")[0];
    if (isCanvasBlank(canvas)) {
      // Get the modal
      viewModal("myModal");
      return;
    }
    var dataURL = canvas.toDataURL();
    data.append("signature", dataURL);
    console.log(JSON.stringify(Object.fromEntries(data)));
    var axios = require("axios");
    var config = {
      method: "POST",
      url: `${HTTPS_URL}/cases/${localStorage.getItem("user")}/histories/e-sign/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        viewModal("thankYouModal");
      })
      .catch(function (error) {
        console.log(error);
        viewModal("thankYouModal");
      });
  }; */
  /* const signPage = (id) => {
    var axios = require("axios");
    var config = {
      method: "GET",
      url: `${HTTPS_URL}/cases/${localStorage.getItem("user")}/histories/e-sign/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios(config)
      .then((response) => {
        console.log("resdata", response.data);
        document.getElementById("tasks-container").innerHTML =
          `<div class='signature-container'> <form  id='SignForm' ` +
          response.data.mock +
          `
          <input id="fullName" placeholder='Please enter your full name'>
          <input id="fillCanvas" type='button' value='Type to Sign'>

          <select name="fonts" id="fonts">
            <option value="70px Allura-Regular">Allura-Regular</option>
            <option value="150px MuseoSans_100">Comic Sans</option>
            <option value="20px Helvetica">Helvetica</option>
          </select>
          <div id='signature'></div>
              <input id="submitButton" type='button' value='Submit'>
              <input id="clearButton" type='button' value='Delete'>
            </form>
          
          <div id="myModal" class="modal">
            <!-- Modal content -->
            <div class="modal-content">
              <span class="close">&times;</span>
              <p>Signature is required to Submit</p>
            </div>
          </div>
          
          <div id="thankYouModal" class="modal">
            <!-- Modal content -->
            <div class="modal-content">
              <span class="close">&times;</span>
              <p>Thank you for your submit!</p>
            </div>
          </div>
          </div>`;

        document.getElementById("fillCanvas").addEventListener("click", fillCanvas.bind(null));
        document.getElementById("submitButton").addEventListener("click", submitForm.bind(null, id));

        document.getElementById("clearButton").addEventListener("click", clearForm.bind(null, id));

        ReactDOM.render(
          <SignatureCanvas
            className="signature"
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "sigCanvas",
            }}
          ></SignatureCanvas>,
          document.getElementById("signature")
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */
  const fillCanvas = () => {
    var e = document.getElementById("fonts");
    var fontVal = e.value;
    var name = document.getElementById("fullName").value;
    var canvas = document.getElementsByClassName("sigCanvas")[0];
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = fontVal;
    ctx.fillText(name, 30, 100);
  };

  const clearForm = () => {
    var canvas = document.getElementsByClassName("sigCanvas")[0];
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  useEffect(() => {
    dispatch(getHistoriesSignAction(props.page, createSearchUrl("query")));
  }, [])

  const columns = React.useMemo(
    () => props.page === "dr-rot-files" ? drRotFilesColumn(loading, fileDownload) : defaultColumn(loading, fileDownload),
    []
  );
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log(e);
      gotoPage(e.target.value - 1);
      e.target.value = "";
    }
  };
  const tableDAta = useTable(
    {
      columns,
      data: historiesSign.historyData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    useSticky,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    data,
    gotoPage,
    setPageSize,
    pageSize,
    state,
    setGlobalFilter,
    // totalRecords,
  } = tableDAta;
  console.log("page",page)
  let recordsInfoText = `Showing ${1}-${page.length} of ${data.length} entries`;
  const { globalFilter } = state;
  return (
    <div>
      {historiesSign.fetching ? <BounceLoader color={"#7FAD40"} loading={true} css={override} size={150} /> : null}
      {historiesSign.fetched && historiesSign.fetching === false ? (
        <div id="tasks-container" className="tasks-container">
          <Styles>
            <div className="rt-table">
              <div className="heading-container">
                <span className="heading">
                  {
                    props.page === "e-sign" ? "Tasks" :
                      props.page === "dr-files" ? "Documents to Read" :
                        props.page === "dr-rot-files" ? "ROT - Documents to Read" :
                          props.page === "ea-rot-files" ? "ROT - Documents to E-Sign" :
                            props.page === "ws-rot-files" ? "ROT - Documents to West Sign" :
                              props.page === "ssl-rot-files" ? "ROT - All SLL Correspondents"
                                : ""
                  }
                </span>
                {
                  /*
                  
                  <div className="pagination">
                  <span className="pagination-info" style={{ marginRight: "10px" }}>
                    Show
                  </span>
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <span className="pagination-info" style={{ marginLeft: "10px" }}>
                    Entries
                  </span>
                </div>
                  */
                }

              </div>
              <div style={{ paddingLeft: 20, paddingRight: 40 }}>
                <SearchField
                  page={props.match.params.page}
                  path={
                    props.page === "e-sign" ? "/main/tasks/" :
                      props.page === "dr-files" ? "/main/documents-to-read/" :
                        props.page === "dr-rot-files" ? "/main/report-on-title/dr-rot-files/" :
                          props.page === "ea-rot-files" ? "/main/report-on-title/es-rot-files/" :
                            props.page === "ws-rot-files" ? "/main/report-on-title/ws-rot-files/" :
                              props.page === "ssl-rot-files" ? "/main/report-on-title/ssl-rot-files/"
                                : ""
                  }
                  searchText={getSearchInitValue("search")}
                  searchStartDate={getSearchInitValue("start_date")}
                  searchEndDate={getSearchInitValue("end_date")}
                  searchSigned={getSearchInitValue("signed")}
                />
              </div>
              <table {...getTableProps()} className="table stickyTable">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr className="row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Styles>
          <div className="bottom-controls">
            <span className="records-info">
              {
                historiesSign.meta !== undefined ? (
                  <div>Showing {historiesSign.meta.from}-{historiesSign.meta.to + 1} of {historiesSign.meta.total} entries</div>
                ) : null
              }
            </span>
            <div className="react-paginate">
              <Paginate
                onChange={(e) => {
                  props.history.push(createSearchUrl("url", e.valueOf()));
                  window.location.reload();
                }}
                prevPageText={"⟨"}
                firstPageText={"«"}
                lastPageText={"»"}
                nextPageText={"⟩"}
                itemsCountPerPage={historiesSign.meta.per_page}
                itemClass={"pagination"}
                activePage={parseInt(props.match.params.page)}
                getPageUrl={(e) => createSearchUrl("url", e.valueOf())}
                totalItemsCount={historiesSign.meta.total}
                pageRangeDisplayed={5}
                linkClass="linkClass"
                activeLinkClass="activeLinkClass"
              />
            </div>
            <div className="buffer"></div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default withRouter(Tasks);
