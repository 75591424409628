const initialState = {
    fetching: false,
    fetched: false,
    historyData: [],
    meta: {},
    error: false
}
export const getFlSignReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FL_SIGN_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_FL_SIGN_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                historyData: action.payload.data,
                meta: action.payload.meta
            }
        case 'GET_FL_SIGN_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}