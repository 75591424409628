import axios from "../../../helpers/axios"

export const loggedUserAction = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'LOGGED_USER_PENDING',
            })
            const res = await axios.get('/logged-user')
            localStorage.setItem("u_data", JSON.stringify(res.data.data))
            dispatch({
                type: 'LOGGED_USER_DONE',
                payload: res.data.data
            })
        } catch (error) {
            dispatch({
                type: 'LOGGED_USER_ERROR',
                payload: error
            })
        }
    }
}