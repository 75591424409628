import React from "react";
import Overview from "../overview/overview.component";
import { Footer } from "../footer/footer.component";
import Tasks from "../tasks/tasks.component";
import TaskSign from "../tasks/task.sign.component";
import DrFile from "../tasks/dr-files.component";
import ReportOnTitle from "../rot-documents/rot-overview.component";
import RotDocuments from "../rot-documents/rot-documents.component";
import { CaseHistory } from "../case-history/case-history.component";
import { Contact } from "../contact/contact.component";
import FirstLetters from "../first-letters/fl-overview.component";
import FlWetSign from "../first-letters/pages/PrintAndSignWW";
import FlEsignOnly from "../first-letters/pages/EsignOnly";
import Payment from "../payment/payment.component"
import AfterPayment from "../payment/afterPayment"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrintAndSignWW from "../rot-documents/pages/PrintAndSignWW";
import PrintAndSignW from "../rot-documents/pages/PrintAndSignW";
import EsignOnly from "../rot-documents/pages/EsignOnly";
import ReadBefore from "../rot-documents/pages/ReadBefore";

export const MainView = ({ userName }) => {
  var session_token = localStorage.getItem("token");

  return (
    <div className="content-footer-container">
      <div>
        <Switch>
          <Route
            path="/main/overview"
            render={(props) =>
              session_token ? (
                <Overview {...props} userName={userName} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/case-history/:page"
            render={(props) =>
              session_token ? (
                <CaseHistory {...props} userName={userName} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/tasks/:page"
            render={(props) =>
              session_token ? (
                <Tasks {...props} page="e-sign" userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/documents-to-read/:page"
            render={(props) =>
              session_token ? (
                <DrFile {...props} page="dr-files" userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/rot-overview"
            render={(props) =>
              session_token ? (
                <ReportOnTitle {...props} userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/report-on-title/print-and-sign-ww"
            render={(props) =>
              session_token ? (
                <PrintAndSignWW
                  {...props}
                  page="print-and-sign-ww"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/report-on-title/print-and-sign-w"
            render={(props) =>
              session_token ? (
                <PrintAndSignW
                  {...props}
                  page="print-and-sign-w"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/report-on-title/esign-only/:id"
            render={(props) =>
              session_token ? (
                <EsignOnly {...props} userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/report-on-title/read-before/:page"
            render={(props) =>
              session_token ? (
                <ReadBefore {...props} page="read-before" userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />

          <Route
            path="/main/task-sign/:id"
            render={(props) =>
              session_token ? (
                <TaskSign {...props} userName={userName} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/contact"
            render={(props) =>
              session_token ? (
                <Contact {...props} userName={userName} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />

          <Route
            path="/main/purchase-docs/fl-overview"
            render={(props) =>
              session_token ? (
                <FirstLetters
                  {...props}
                  userName={userName}
                  document_metter="PURCHASE"
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/purchase-docs/first-letters/wet-sign"
            render={(props) =>
              session_token ? (
                <FlWetSign
                  {...props}
                  page="wet-sign"
                  document_metter="PURCHASE"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/purchase-docs/first-letters/esign-only"
            render={(props) =>
              session_token ? (
                <FlEsignOnly
                  {...props}
                  page="esign-only"
                  document_metter="PURCHASE"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/purchase-docs/rot-overview"
            render={(props) =>
              session_token ? (
                <ReportOnTitle {...props} userName={userName} />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/purchase-docs/other"
            render={(props) =>
              session_token ? (
                <Tasks
                  {...props}
                  page="e-sign"
                  userName={userName}
                  document_metter="PURCHASE"
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />

          <Route
            path="/main/sale-docs/fl-overview"
            render={(props) =>
              session_token ? (
                <FirstLetters
                  {...props}
                  userName={userName}
                  document_metter="SALE"
                />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/sale-docs/first-letters/wet-sign"
            render={(props) =>
              session_token ? (
                <FlWetSign
                  {...props}
                  page="wet-sign"
                  document_metter="SALE"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/sale-docs/first-letters/esign-only"
            render={(props) =>
              session_token ? (
                <FlEsignOnly
                  {...props}
                  page="esign-only"
                  document_metter="SALE"
                  userName={userName}
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/sale-docs/other"
            render={(props) =>
              session_token ? (
                <Tasks
                  {...props}
                  page="e-sign"
                  userName={userName}
                  document_metter="SALE"
                />
              ) : (
                <Redirect to={{ pathname: "/" }} />
              )
            }
          />
          <Route
            path="/main/payment"
            render={(props) =>
              session_token ? (
                <Payment />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
          <Route
            path="/main/afterPayment"
            render={(props) =>
              session_token ? (
                <AfterPayment />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              )
            }
          />
        </Switch>
      </div>
      <Footer></Footer>
    </div>
  );
};
