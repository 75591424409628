import "./not-support.css";
import logoLight from "../../assets/logo.svg";
import chrome from "../../assets/chrome.svg";
import firefox from "../../assets/firefox.svg";
import opera from "../../assets/opera.svg";
import edge from "../../assets/edge.svg";
import safari from "../../assets/safari.svg";

const NotSupport = () => {
  return (
    <main class="bsod container">
      <div style={{display:"flex", width:"100%", justifyContent: "center"}}>
        <img src={logoLight} alt="avrillo logo" className="logo" width="150" />
      </div>
      <h2 class="neg title"><span class="bg">Your Internet Explorer is no longer supported by Microsoft.</span></h2>
      <p>Simply copy the link below and paste it to address bar in another browser:</p>
      <p style={{color:"#7fad40", wordBreak: "break-all"}}>{window.location.href}</p>
      <p>To continue use an alternative browser for example any of these below:</p>
      <nav class="nav">
        <a href="https://www.google.com/intl/en/chrome/" class="link"><img className="browser-ico" src={chrome} /> <span>Chrome</span></a>&nbsp;
        |&nbsp;<a href="https://www.mozilla.org/en-US/firefox/new/" class="link"><img className="browser-ico" src={firefox} />Firefox</a>&nbsp;
        |&nbsp;<a href="https://www.opera.com" class="link"><img className="browser-ico" src={opera} />Opera</a>&nbsp;
        |&nbsp;<a href="https://www.microsoft.com/en-us/edge" class="link"><img className="browser-ico" src={edge} />Microsoft Edge</a>&nbsp;
        |&nbsp;<a href="https://www.apple.com/uk/safari/" class="link"><img className="browser-ico" src={safari} />Safari (for MacOS)</a>
      </nav>
      <h2 class="neg title"><span class="bg">What does this mean?</span></h2>
      <p>It’s time to act. Since 1/12/16, Microsoft no longer provides security updates or technical support for old versions of Internet Explorer. Regular security updates help protect devices so upgrading and staying current is important.</p>
      <p>Please see details here:<br></br> <a style={{color:"#7fad40"}} href="https://www.microsoft.com/en-ww/microsoft-365/windows/end-of-ie-support">https://www.microsoft.com/en-ww/microsoft-365/windows/end-of-ie-support</a></p>

    </main>
  )
}

export default NotSupport

/*
<p>* Internet Explorer is not supported.<br />
         Switch to other browsers for better performance.<br />
      Supported browsers are listed below, you can click and download. </p>
*/