import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Meantime from "./components/meantime";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux"
import { store } from "./redux/store";
import NotSupport from './components/not-support';

const isIE = /*@cc_on!@*/false || !!document.documentMode;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {
        isIE ? <NotSupport /> : <App />  //Meantime />
      }
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
