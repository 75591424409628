const initialState = {
    fetching: false,
    fetched: false,
    data: {},
    error: null
}

export const getCasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CASES_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_CASES_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.data
            }
        case 'GET_CASES_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}