import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/find.svg";
import "./search-field.style.scss";

export const SearchField = ({ page, searchText, searchStartDate, searchEndDate }) => {
  const [text, setText] = useState(searchText);
  const [startDate, setStartDate] = useState(searchStartDate);
  const [endDate, setEndDate] = useState(searchEndDate);


  const handle = () => {
    let initUrl = `${page}?`
    if (text !== "") {
      initUrl = `${initUrl}&search=${text}`
    }
    if (startDate !== "") {
      initUrl = `${initUrl}&start_date=${startDate}`
    }
    if (endDate !== "") {
      initUrl = `${initUrl}&end_date=${endDate}`
    }

    window.location.href = `/main/case-history/${initUrl}`
  }

  return (
    <div className="search-container">
      <div className="searchBox">
        <label>Find (i.e. details, number etc.)</label>
        <input value={text} type="text" placeholder="Find " onChange={(e) => setText(e.target.value)} />
      </div>
      {
        /*
        <div className="searchBox dateBox">
        <label>Start Date</label>
        <input value={startDate} type="date" onChange={(e) => setStartDate(e.target.value)} />
      </div>
      <div className="searchBox dateBox">
        <label>End Date</label>
        <input value={endDate} type="date" onChange={(e) => setEndDate(e.target.value)} />
      </div>
        */
      }
      <div className="searchBox">
        <button onClick={handle}>
          Find
        </button>
      </div>
    </div>
  );
};
