import React, { useEffect, useState } from "react";
import { CollapsibleCards } from "../collapsible-cards/collapsible-cards.component";
import { InfoContainer } from "../info-container/info-container.component";
import { ProgressBar } from "../progress-bar/progress-bar.component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useMediaQuery } from "react-responsive";
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";
import { HTTP_URL } from "../config";
import "./overview.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCasesAction, getPurchaseStepsAction, getSaleStepsAction } from "../../redux/actions/overview";
const Overview = (props) => {
  const cases = useSelector((state) => state.cases);
  const auth = useSelector((state) => state.auth);
  const saleSteps = useSelector((state) => state.saleSteps);
  const purchaseSteps = useSelector((state) => state.purchaseSteps);


  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    dispatch(getCasesAction());
    dispatch(getSaleStepsAction());
    dispatch(getPurchaseStepsAction());
  }, []);
  const saleOrPurchase = (purchaseOrSale, ismobile) => {
    if (purchaseOrSale === "Sale and Purchase" && ismobile) {
      return (
        <Tabs>
          <TabList>
            <Tab>PURCHASE</Tab>
            <Tab>SALE</Tab>
          </TabList>

          <TabPanel>
            <div className="process">
              <ProgressBar Percent={purchaseSteps.steps.to_exchange_percentage} StepsCompleteNumber={purchaseSteps.steps.to_exchange_completed_step_count} TotalStep={purchaseSteps.steps.to_exchange_total_step_count} isPurchase={true} />
              <InfoContainer
                data={cases.data}
                caseStartDate={cases.data.case_start_date}
                estimatedComplete={cases.data.estimated_completion_date}
                stepsComplete={purchaseSteps.steps.to_exchange_completed_step_count}
                completePercent={purchaseSteps.steps.to_exchange_percentage}
                isPurchase={true}
                address={
                  //TODO: bunları property_purchase olarak değiştir, backend'den eksik geliyor şu anda
                  cases.data.property_purchase.address_line1 + ", " + cases.data.property_purchase.address_line2 + ", " + cases.data.property_purchase.postcode
                }
              />
              <CollapsibleCards userName={localStorage.getItem("user")} stepsData={purchaseSteps} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="process">
              <ProgressBar Percent={saleSteps.steps.to_exchange_percentage} StepsCompleteNumber={saleSteps.steps.to_exchange_completed_step_count} TotalStep={saleSteps.steps.to_exchange_total_step_count} isPurchase={false} />
              <InfoContainer
                data={cases.data}
                caseStartDate={cases.data.case_start_date}
                estimatedComplete={cases.data.estimated_completion_date}
                stepsComplete={saleSteps.saleStepNumber}
                completePercent={saleSteps.salePercent}
                isPurchase={false}
                address={cases.data.property_sale.address_line1 + ", " + cases.data.property_sale.address_line2 + ", " + cases.data.property_sale.postcode}
              />
              <CollapsibleCards userName={localStorage.getItem("user")} stepsData={saleSteps} />
            </div>
          </TabPanel>
        </Tabs>
      );
    } else if (purchaseOrSale === "Sale and Purchase" && !isMobile) {
      return (
        <div className="double">
          <div className="process">
            <ProgressBar Percent={purchaseSteps.steps.to_exchange_percentage} StepsCompleteNumber={purchaseSteps.steps.to_exchange_completed_step_count} TotalStep={purchaseSteps.steps.to_exchange_total_step_count} isPurchase={true} />
            <InfoContainer
              data={cases.data}
              caseStartDate={cases.data.case_start_date}
              estimatedComplete={cases.data.estimated_completion_date}
              stepsComplete={purchaseSteps.purchaseStepNumber}
              completePercent={purchaseSteps.steps.to_exchange_percentage}
              isPurchase={true}
              address={
                //TODO: property_sale => property_purchase ile değiştirilecek
                cases.data.property_purchase?.address_line1 +
                ", " +
                //TODO: property_sale => property_purchase ile değiştirilecek
                cases.data.property_purchase?.address_line2 +
                ", " +
                //TODO: property_sale => property_purchase ile değiştirilecek
                cases.data.property_purchase?.postcode
              }
            />
            <CollapsibleCards userName={localStorage.getItem("user")} stepsData={purchaseSteps} />
          </div>
          <div className="process">
            <ProgressBar Percent={saleSteps.steps.to_exchange_percentage} StepsCompleteNumber={saleSteps.steps.to_exchange_completed_step_count} TotalStep={saleSteps.steps.to_exchange_total_step_count} isPurchase={false} />
            <InfoContainer
              data={cases.data}
              caseStartDate={cases.data.case_start_date}
              estimatedComplete={cases.data.estimated_completion_date}
              stepsComplete={saleSteps.saleStepNumber}
              completePercent={saleSteps.steps.to_exchange_percentage}
              isPurchase={false}
              address={cases.data.property_sale.address_line1 + ", " + cases.data.property_sale.address_line1 + ", " + cases.data.property_sale.postcode}
            />
            <CollapsibleCards userName={localStorage.getItem("user")} stepsData={saleSteps} />
          </div>
        </div>
      );
    } else if (purchaseOrSale === "Purchase") {
      return (
        <div className="process">
          <ProgressBar Percent={purchaseSteps.steps.to_exchange_percentage} StepsCompleteNumber={purchaseSteps.steps.to_exchange_completed_step_count} TotalStep={purchaseSteps.steps.to_exchange_total_step_count} isPurchase={true} />
          <InfoContainer
            data={cases.data}
            caseStartDate={cases.data.case_start_date}
            estimatedComplete={cases.data.estimated_completion_date}
            stepsComplete={purchaseSteps.purchaseStepNumber}
            completePercent={purchaseSteps.steps.to_exchange_percentage}
            isPurchase={true}
            address={
              cases.data.property_purchase.address_line1 + ", " + cases.data.property_purchase.address_line2 + ", " + cases.data.property_purchase.postcode
            }
          />
          <CollapsibleCards userName={localStorage.getItem("user")} stepsData={purchaseSteps} />
        </div>
      );
    } else if (purchaseOrSale === "Sale") {
      return (
        <div className="process">
          <ProgressBar Percent={saleSteps.steps.to_exchange_percentage} StepsCompleteNumber={saleSteps.steps.to_exchange_completed_step_count} TotalStep={saleSteps.steps.to_exchange_total_step_count} isPurchase={false} />
          <InfoContainer
            data={cases.data}
            caseStartDate={cases.data.case_start_date}
            estimatedComplete={cases.data.estimated_completion_date}
            stepsComplete={saleSteps.saleStepNumber}
            completePercent={saleSteps.steps.to_exchange_percentage}
            isPurchase={false}
            address={cases.data.property_sale.address_line1 + ", " + cases.data.property_sale.address_line2 + ", " + cases.data.property_sale.postcode}
          />
          <CollapsibleCards userName={localStorage.getItem("user")} stepsData={saleSteps} />
        </div>
      );
    }
  };
  return (
    <div className="overview">
      {cases.fetching ? <BounceLoader color={"#7FAD40"} loading={true} css={override} size={150} /> : null}
      {cases.fetched && cases.fetching === false ? saleOrPurchase(cases.data.matter_type, isMobile) : null}
      
    </div>

  );
};

export default React.memo(Overview);
