import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import "./header.style.scss";
import logoLight from "../../assets/logo.svg";
import logoDark from "../../assets/logo.svg";
import folderIcon from "../../assets/folder.svg";
import hamburgerBtn from "../../assets/hamburger.svg";
import darkThemeButtonIcon from "../../assets/mode-dark.svg";
import lightThemeButtonIcon from "../../assets/mode-light.svg";
import { useDispatch, useSelector } from "react-redux";
import { loggedUserAction, logoutAction } from "../../redux/actions/login";
import { taskCountAction } from "../../redux/actions/task/taskCount";
import classnames from "classnames";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

const Header = ({
  openMenu,
  theme,
  setThemeColors,
  setTheme,
  history,
  location,
  match,
}) => {
  const [menusActive, setMenusActive] = useState({ task: false, rok: false });
  const [matter_type, setMatterType] = useState(false);
  const [payment, setPayment] = useState(false);
  const taskCount = useSelector((state) => state.taskCount);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutAction());
    history.push("/");
  };
  useEffect(() => {
    dispatch(taskCountAction());
  }, []);
  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 0) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  useEffect(() => {
    if (taskCount.fetched) {
      setMatterType(taskCount.data.matter_type);
      if (taskCount.data.payment) {
        setPayment(taskCount.data.payment);
      }
    }
  }, [taskCount]);
  return (
    <div>
      <div className="header" id="header">
        <Link to="/main/overview" className="logo-a">
          <img
            src={theme === "dark" ? logoDark : logoLight}
            alt="avrillo logo"
            className="logo"
          />
        </Link>
        <div className="linksContainer">
          <Link
            to="/main/overview"
            className={classnames("headerLink", {
              active: location.pathname === "/main/overview",
            })}
          >
            MILESTONES
          </Link>
          {taskCount.fetched && (matter_type === "P" || matter_type === "B") && (
            <>
              <Menu
                menuButton={
                  <MenuButton
                    className={classnames("headerLink", {
                      active: location.pathname.search("purchase-docs") >= 0,
                    })}
                  >
                    DOCUMENTS TO READ/SIGN - PURCHASE
                  </MenuButton>
                }
              >
                <MenuItem>
                  <Link
                    to="/main/purchase-docs/fl-overview"
                    className={classnames("menu-link", {
                      active:
                        location.pathname.search("purchase-docs") >= 0 &&
                        (location.pathname.search("fl") >= 0 ||
                          location.pathname.search("first-letters") >= 0),
                    })}
                  >
                    1st Letter Documents to Sign
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link
                    to="/main/purchase-docs/rot-overview"
                    className={classnames("menu-link", {
                      active:
                        location.pathname.search("purchase-docs") >= 0 &&
                        (location.pathname.search("rot") >= 0 ||
                          location.pathname.search("report-on-title") >= 0),
                    })}
                  >
                    Report on Title
                  </Link>
                </MenuItem>
                {/* <MenuItem>
              <Link to="/main/purchase-docs/other" className={classnames("menu-link", {
                "active": location.pathname.search("purchase-docs/other") >= 0
              })} >
                Other
              </Link>
            </MenuItem>
            */}
              </Menu>
            </>
          )}
          {matter_type && (matter_type === "S" || matter_type === "B") && (
            <>
              <Menu
                menuButton={
                  <MenuButton
                    className={classnames("headerLink", {
                      active: location.pathname.search("sale-docs") >= 0,
                    })}
                  >
                    DOCUMENTS TO READ/SIGN - SALE
                  </MenuButton>
                }
              >
                <MenuItem>
                  <Link
                    to="/main/sale-docs/fl-overview"
                    className={classnames("menu-link", {
                      active:
                        location.pathname.search("sale-docs") >= 0 &&
                        (location.pathname.search("fl") >= 0 ||
                          location.pathname.search("first-letters") >= 0),
                    })}
                  >
                    1st Letter Documents to Sign
                  </Link>
                </MenuItem>
                {/* 
            <MenuItem>
              <Link to="/main/sale-docs/other" className={classnames("menu-link", {
                "active": location.pathname.search("sale-docs/other") >= 0
              })} >
                Other
              </Link>
            </MenuItem>
            */}
              </Menu>
            </>
          )}
          <Link
            to="/main/case-history/1"
            className={classnames("headerLink", {
              active: location.pathname.search("case-history/") >= 0,
            })}
          >
            CASE HISTORY
          </Link>
          {payment && (
            <Link to="/main/payment" className="headerLink">
              PAYMENT
            </Link>
          )}

          <button className="hamburgerBtn" onClick={openMenu}>
            <img src={hamburgerBtn} alt="menu icon" />
          </button>
        </div>
        <div className="loginContainer">
          <div>
            <div className="userInfo">
              <img src={folderIcon} alt="folder icon" />
              <p>{localStorage.getItem("user")}</p>
              <span>
                {JSON.parse(localStorage.getItem("u_data")).forename}{" "}
                {JSON.parse(localStorage.getItem("u_data")).surname}
              </span>
            </div>

            {/* <div className="logoutBtn">
              <button onClick={pullData}>Logout</button>
            </div> */}
          </div>
          <div className="themeBtn">
            <button
              onClick={() => {
                if (theme === "dark") {
                  setTheme("light");
                  setThemeColors("light");
                  localStorage.setItem("theme", "light");
                } else {
                  setTheme("dark");
                  setThemeColors("dark");
                  localStorage.setItem("theme", "dark");
                }
              }}
            >
              <img
                src={
                  theme === "light" ? darkThemeButtonIcon : lightThemeButtonIcon
                }
                alt="change theme"
                className="mode-dark"
                height="25"
              />
            </button>
          </div>
          <span className="logoutBtn">
            <button
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(React.memo(Header));
