import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CollapsibleCards } from "./collapsible/collapsible-cards/collapsible-cards.component";
import { FiChevronLeft, FiChevronRight, FiCheck, FiX } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";
import { HTTPS_URL } from "../config";
import "./payment.style.scss";
import Swal from "sweetalert2";
import { isEmptyObject } from "jquery";
import { useHistory } from "react-router-dom";
//import { not } from "cheerio/lib/api/traversing";
function Payment() {
  const [items, setItems] = useState([]);
  const [buyable, setBuyable] = useState([]);
  const [not_paid_but_recommended, setNpbr] = useState([]);
  const [paids, setPaids] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selected_codes, setSelectedCodes] = useState([]);
  const [selected_search_pricing, setSelectedPrice] = useState(0);
  const [active_pack, setActivePack] = useState(0);
  const [isOpenModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const price = useSelector((state) => state.payment);
  const user = useSelector((state) => state.auth);
  const data = price.checkedItems;
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    dispatch({ type: "resetPrice" });
    const case_ref = localStorage.getItem("user");
    const url = `${HTTPS_URL}/cases/searches/${case_ref}`;
    axios(`${HTTPS_URL}/cases/searches/${case_ref}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(async (response) => {
      if (
        response.data.packages.length === 0 &&
        response.data.paids.length === 0 &&
        response.data.not_paid_but_recommended.length === 0
      ) {
        history.push("/main/overview");
      }
      response.data.packages.map((item) => {
        item.search.includes.map((include) => {
          include.collapsed = "false";
        });
        item.recommended_searches.map((rs) => {
          rs.collapsed = "false";
        });
      });
      setItems(response.data.packages);
      setBuyable(response.data.buyable);
      setNpbr(response.data.not_paid_but_recommended);
      setPaids(response.data.paids);
    });
  }, []);

  useEffect(() => {
    if (selected) {
      let totaly = 0;
      selected.map((item) => {
        totaly = eval(totaly) + eval(item.total_fee);
      });
      setSelectedPrice(totaly);
    }
  }, [selected]);

  const handleSubmit = () => {
    if (paymentAmount() <= 0) {
      Swal.fire({
        title: "Error!",
        text: "Payment amount is not valid",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }
    setSubmitDisabled(true);
    setOpenModal(true);
    localStorage.removeItem("selected");
    let selected_pack = false;
    const case_ref = localStorage.getItem("user");
    if(items.length > 0){
      selected_pack = items[active_pack].search.search_code;
      if (items[active_pack].id === "optionals") {
        selected_pack = false;
        optionals_val = true;
      }
    }
    let optionals_val = false;
    let data = {
      con_case_ref: case_ref,
      package: selected_pack,
      optionals: optionals_val,
      selected_searches: selected_codes,
      not_paid_but_recommended: not_paid_but_recommended.length > 0 ? true : false,
    };
    try {
      axios(`${HTTPS_URL}/cases/search-payment/${case_ref}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: data,
      })
        .then(async (response) => {
          console.log(response)
          if (response.data) {
            let searches = [...selected_codes, items[active_pack].search_code];
            setSubmitDisabled(false);
            localStorage.setItem("searches", searches);
            if (response.data.data?.redirectUrl) {
              setModalUrl(response.data.data?.redirectUrl);
            }
          } else {
            setOpenModal(false);
            setModalUrl("");
            setSubmitDisabled(false);
            Swal.fire({
              title: "Error!",
              text: "An unexpected error has occurred. Contact the IT team",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        })
        .catch(function (error) {
          console.log(error)
          Swal.fire({
            title: "Error!",
            text: "An unexpected error has occurred. Contact the IT team",
            icon: "error",
            confirmButtonText: "Ok",
          });
          setSubmitDisabled(false);
          setOpenModal(false);
          setModalUrl("");
        });
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: "An unexpected error has occurred. Contact the IT team",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setSubmitDisabled(false);
      setOpenModal(false);
      setModalUrl("");
    }
  };
  const selectPack = (pack_id) => {
    setSelected([]);
    setSelectedCodes([]);
    setActivePack(pack_id);
  };

  const packTotals = (i) => {
    let pack_total = eval(items[i].search_fee);
    if (
      items[i].recommended_searches &&
      items[i].recommended_searches.length > 0
    ) {
      items[i].recommended_searches
        .filter((qq) => {
          if (qq.req === "Yes") {
            return true;
          }
        })
        .map((re) => {
          pack_total = pack_total + eval(re.search_fee);
        });
    }
    if (not_paid_but_recommended.length > 0) {
      not_paid_but_recommended.map((re) => {
        pack_total = pack_total + eval(re.search.search_fee);
      });
    }
    return pack_total;
  };
  const buyableItems = () => {
    let buyables = 0;
    let buyable_pack = items.filter((qq) => {
      if (qq.paid === "No") {
        return true;
      }
    }).length;
    let not_paid_count = not_paid_but_recommended.length;
    console.log("buyableeeee : ", items);
    return buyable.length + buyable_pack + not_paid_count;
  };
  const paymentAmount = () => {
    let discount = 0;
    let extras = 0;
    let pack_total = 0;
    if (items[active_pack]) {
      pack_total = unDiscountedPrice(active_pack);
      discount = items[active_pack].discount;
      if (items[active_pack].search_code === "SEARCH-AVRPACK") {
        discount += 400;
      }
    }
    selected.map((item) => {
      extras = eval(extras) + eval(item.total_fee);
    });
    not_paid_but_recommended.map((item) => {
      extras = eval(extras) + eval(item.search.total_fee);
    });
    console.log(extras)
    return eval(pack_total) - eval(discount) + eval(extras);
  };

  const deleteItem = (e) => {
    setSelectedCodes(selected_codes.filter((item) => item !== e.search_code));
    setSelected(selected.filter((item) => item.search_code !== e.search_code));
  };

  const selectItem = (item) => {
    setSelected((selected) => [...selected, item]);
    setSelectedCodes((selected_codes) => [...selected_codes, item.search_code]);
  };

  const unDiscountedPrice = (i) => {
    if (!items[i]) {
      return 0;
    }
    const item = items[i];
    let price = 0;
    if (item.search.search_code === "SEARCH-AVRPACK") {
      if (item.paid === 'No' || item.paid === 'no') {
        price = item.search.total;
      }
    } else {
      price = item.search_fee;
    }
    item.recommended_searches
      ?.filter((qq) => {
        if (qq.req === "Yes") {
          return true;
        }
      })
      .map((i) => {
        price = eval(price) + eval(i.search.total_fee);
      });
    not_paid_but_recommended.map((i) => {
      price = eval(price) + eval(i.search_fee);
    });
    return price;
  };
  const withoutInsuranceDiscount = (item) => {
    let insurance_price = items[active_pack].discount;
    return eval(insurance_price);
  };

  const onlyInsuranceDiscount = () => {
    let insurance_price = 0;
    if (items[0].paid !== 'Yes' && items[0].paid !== 'yes') {
      items[0].search.includes.map((include, i) => {
        if (include.search_code === "INDEMNITY-INSURANCE") {
          insurance_price = eval(include.total_fee);
        }
      });
    }

    return insurance_price;
  };

  const closeModal = () => {
    location.reload();
    setOpenModal(false);
  };
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
    }).format(value);
  return (
    <div className="payment-table">
      {isOpenModal ? (
        <div className="amount-screen">
          <div className="amount-iframe">
            <div className="ai-header">
              <h3>Searches Payment</h3>
              <button
                className="close-modal"
                onClick={() => {
                  closeModal();
                }}
              >
                <FiX />
              </button>
            </div>
            <div className="ai-body">
              <iframe src={modalUrl} id="payment-iframe"></iframe>
            </div>
          </div>
        </div>
      ) : null}
      <div className={buyableItems() ? "table-area" : "fw"}>
        {buyableItems() > 0 && (
          <div className="card">
            <div className="card-header">
              <FiChevronLeft className="back-button" />
              Payment
            </div>
            <div className="card-body price-table-card-body">
              {buyableItems() > 0 && (
                <>
                  {items.map((item, i) => {
                    if (item.paid !== 'Yes' && item.paid !== 'yes') {
                      return ((
                        <div
                          key={i}
                          className={
                            active_pack === i ? "custom-card active" : "custom-card"
                          }
                        >
                          <div
                            onClick={() => {
                              selectPack(i);
                            }}
                            className="custom-card-header"
                          >
                            <span className="title">
                              {item.search.search_label}
                              <br />
                              <small>{item.search.search_description}</small>
                            </span>
                            {item.id !== "optionals" && (
                              <span className="price">
                                {numberFormat(packTotals(i))}
                              </span>
                            )}
                            {item.discount > 0 && (
                              <div className="discounted-badge">RECOMMENDED</div>
                            )}
                          </div>
                          {item.discount > 0 || item.search_code == 'SEARCH-AVRPACK' && (
                            <div className="undiscounted-price">
                              {numberFormat(unDiscountedPrice(i))}
                            </div>
                          )}
                          <div className="custom-card-body">
                            {/*  <p class="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Orci elementum malesuada eget convallis sapien.
                              Ornare eget dictumst odio nibh. Eu vel semper iaculis
                              aliquam.
                          </p>*/}
                            <div className="check-list">
                              {item.search.includes.length > 0 &&
                                item.id !== "optionals" ? (
                                <>
                                  <div className="check-list-header">Searches</div>
                                  {item.search.includes
                                    .filter((e) => {
                                      if (e.search_code !== "INDEMNITY-INSURANCE") {
                                        return true;
                                      }
                                    })
                                    .map((include, c) => (
                                      <div
                                        key={c}
                                        className={
                                          c % 2 == 0
                                            ? "list-item"
                                            : "list-item right"
                                        }
                                      >
                                        <span className="title">
                                          <FiChevronRight />
                                          <span>{include.search_label}</span>
                                        </span>
                                        <span className="check-box active">
                                          <FaCheck />
                                        </span>
                                        <div className="information-box">
                                          {include.search_description}
                                        </div>
                                      </div>
                                    ))}
                                </>
                              ) : null}

                              {item.recommended_searches.length > 0 ? (
                                <>
                                  {item.recommended_searches
                                    .filter((q) => {
                                      if (q.req === "Yes") {
                                        return true;
                                      }
                                    })
                                    .map((include, n) => (
                                      <div key={n}>
                                        <div
                                          className={
                                            (n + items[i].search.includes.length) %
                                              2 ==
                                              0
                                              ? "list-item"
                                              : "list-item right"
                                          }
                                        >
                                          <span className="title">
                                            <FiChevronRight />
                                            <span>
                                              {include.search.search_label}
                                            </span>
                                          </span>
                                          <span className="check-box active">
                                            <FaCheck />
                                          </span>
                                          <div className="information-box">
                                            {include.search.search_description}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              ) : null}
                              {/* 
                              {item.buyable.length > 0 && (
                                <>
                                  <div className="clear"></div>
                                  {item.id !== "optionals" && (
                                    <div className="check-list-header mt-2">
                                      OPTIONAL SEARCHES
                                    </div>
                                  )}
    
                                  {item.buyable &&
                                    item.buyable.map((include, n) => (
                                      <div key={n}>
                                        <div
                                          className={[
                                            n % 2 == 0
                                              ? "list-item buyable"
                                              : "list-item right buyable",
                                            active_pack !== i ? "disabled" : "",
                                          ].join(" ")}
                                          onClick={() => {
                                            selected_codes.indexOf(
                                              include.search.search_code
                                            ) > -1
                                              ? deleteItem(include.search)
                                              : selectItem(include.search);
                                          }}
                                        >
                                          <span className="title">
                                            <FiChevronRight />
                                            <span>{include.search.search_label}</span>
                                          </span>
                                          <span
                                            className={[
                                              "check-box",
                                              selected_codes.indexOf(
                                                include.search_code
                                              ) > -1
                                                ? "active"
                                                : "",
                                            ].join(" ")}
                                          >
                                            <FaCheck />
                                          </span>
                                          <div className="information-box">
                                            {include.search.search_description}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                              */}
                            </div>
                            {item.search.includes.filter((e) => {
                              if (e.search_code === "INDEMNITY-INSURANCE") {
                                return true;
                              }
                            }).length > 0 && (
                                <div className="check-list float-right">
                                  {item.search.includes.length > 0 &&
                                    item.id !== "optionals" ? (
                                    <>
                                      <div className="check-list-header">
                                        Paid for you by AVRillo
                                      </div>
                                      {item.search.includes
                                        .filter((e) => {
                                          if (
                                            e.search_code === "INDEMNITY-INSURANCE"
                                          ) {
                                            return true;
                                          }
                                        })
                                        .map((include, c) => (
                                          <div
                                            key={c}
                                            className={
                                              c % 2 == 0
                                                ? "list-item"
                                                : "list-item right"
                                            }
                                          >
                                            <span className="title">
                                              <FiChevronRight />
                                              <span>{include.search_label}</span>
                                            </span>
                                            <span className="check-box active">
                                              <FaCheck />
                                            </span>
                                            <div className="information-box">
                                              {include.search_description}
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  ) : null}
                                </div>
                              )}
                          </div>
                        </div>
                      ))
                    }
                  })}
                  <div className="clear"></div>
                  {items.length > 0 &&
                    items[0].search_code != "SEARCH-TWOPACK" && (
                      <>
                        <p className="quote">
                          Risk Warning: If you want to choose the riskier option
                          by ordering less searches, please book a call for
                          surgery with your lawyer.
                        </p>
                      </>
                    )}
                  {items.length > 0 && (
                    <>
                      <p className="quote">
                        Note: However, UK law of “buyer beware” means that you take
                        the risk of financial loss with this riskier pack of
                        searches.
                      </p>
                      <div className="hr"></div>
                    </>
                  )}
                  {not_paid_but_recommended.length > 0 && (
                    <div>
                      <div className="custom-card active ">
                        <div className="custom-card-header">
                          <span className="title not-option">
                            Mandatory Searches
                            <br />
                            <small>
                              Mandatory searches for the property
                            </small>
                          </span>
                        </div>
                        <div className="custom-card-body">
                          <div className="check-list">
                            <>
                              {not_paid_but_recommended.map((include, n) => (
                                <div
                                  key={n}
                                  className={
                                    n % 2 == 0
                                      ? "list-item  mt-0"
                                      : "list-item right  mt-0"
                                  }
                                >
                                  <span className="title">
                                    <FiChevronRight />
                                    <span>{include.search.search_label}</span>
                                  </span>
                                  <span className="check-box active">
                                    <FaCheck />
                                  </span>
                                  <div className="information-box">
                                    {include.search.search_description}
                                  </div>
                                </div>
                              ))}
                            </>
                          </div>
                        </div>
                      </div>
                      <div class="hr"></div>
                    </div>
                  )}
                  {buyable && buyable.length > 0 && (
                    <div>
                      <div className="custom-card active ">
                        <div className="custom-card-header">
                          <span className="title not-option">
                            Optional Searches
                            <br />
                            <small>
                              Searches that you can purchase in addition to the
                              package you selected
                            </small>
                          </span>
                        </div>
                        <div className="custom-card-body">
                          <div className="check-list">
                            <>
                              {buyable.map((include, n) => (
                                <div key={n}>
                                  <div
                                    className="list-item buyable mt-0"
                                    onClick={() => {
                                      selected_codes.indexOf(
                                        include.search.search_code
                                      ) > -1
                                        ? deleteItem(include.search)
                                        : selectItem(include.search);
                                    }}
                                  >
                                    <span className="title">
                                      <FiChevronRight />
                                      <span>{include.search.search_label}</span>
                                    </span>
                                    <span
                                      className={[
                                        "check-box",
                                        selected_codes.indexOf(
                                          include.search_code
                                        ) > -1
                                          ? "active"
                                          : "",
                                      ].join(" ")}
                                    >
                                      <FaCheck />
                                    </span>
                                    <div className="information-box">
                                      {include.search.search_description}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </>
              )}
            </div>
          </div>
        )}
        {paids && paids.length > 0 && (
          <>
            <div className="card w-100 mt-2">
              <div className="card-header">Paid Orders</div>
              <div className="card-body w-100">
                <table class="paid-table">
                  <thead>
                    <th>Label</th>
                    <th width="100">Price</th>
                    <th width="120">Order Date</th>
                    <th width="100">Order Status</th>
                  </thead>
                  <tbody>
                    {paids.map((paid) => (
                      <tr>
                        <td>
                          <b>{paid.search_label}</b>
                        </td>
                        <td>{numberFormat(paid.search_fee)}</td>
                        <td>{paid.order_date}</td>
                        <td>
                          <span className="badge-succes">Paid</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      {
        buyableItems() !== 0 && (
          <div className="payment-info">
            <div className="card">
              <div className="card-header">Billing Summary</div>
              <div className="card-body p-0 w-100">
                <div className="total-list">
                  {items.length > 0 && items[active_pack].id !== "optionals" && (
                    <div className="total-item">
                      <span className="title">
                        {items[active_pack].search.search_label.split(":")
                          .length > 1
                          ? items[active_pack].search.search_label.split(":")[1]
                          : items[active_pack].search.search_label}
                      </span>
                      <span className="price">
                        {numberFormat(unDiscountedPrice(active_pack))}
                      </span>
                    </div>
                  )}
                  {selected.map((select, s) => (
                    <div className="total-item" key={s}>
                      <span className="title">{select.search_label}</span>
                      <span className="price">
                        {numberFormat(select.total_fee)}
                      </span>
                    </div>
                  ))}
                  {items.length > 0 && items[active_pack].discount != 0 && (
                    <div className="total-item">
                      <span className="title text-danger">Discount</span>
                      <span className="price">
                        <span className="text-danger">
                          {items[active_pack].discount > 0 ? "- " : ""}
                          {numberFormat(
                            withoutInsuranceDiscount(
                              items[active_pack].discount
                            )
                          )}
                        </span>
                      </span>
                    </div>
                  )}
                  {items.length > 0 && items[active_pack].search_code == 'SEARCH-AVRPACK' && (
                    <div className="total-item">
                      <span className="title text-danger">
                        Indemnity Ins. (approx.)
                      </span>
                      <span className="price">
                        <span className="text-danger">
                          {items[active_pack].discount > 0 ? "- " : ""}
                          {numberFormat(onlyInsuranceDiscount())}
                        </span>
                      </span>
                    </div>
                  )}
                  <div className="total-item">
                    <span className="title">Amount to be Paid</span>
                    <span className="price">
                      {numberFormat(paymentAmount())}
                    </span>
                  </div>
                </div>
                <div className="clear"></div>
                {paymentAmount() > 0 && (
                  <button
                    className="btn-primary"
                    disabled={submitDisabled}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Complete the Order <FiChevronRight className="check-arrow" />
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      }
    </div >
  );
}

export default Payment;
