const initialState = {
  fetching: false,
  fetched: true,
  data: {},
  error: {}
}
export const taskCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TASK_COUNT_PENDING':
      return {
        ...state,
        fetching: true
      }

    case 'TASK_COUNT_DONE':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      }
    case 'TASK_COUNT_ERROR':
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
}