import React from "react";
import "./footer.style.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <span>
        Copyright &copy; 2021{" "}
        <span style={{ color: "#7fad40" }}>AVRillo </span>, All rights
        reserved.
      </span>
    </div>
  );
};
