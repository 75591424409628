import axios from "../../../helpers/axios";
export const getHistoriesSignAction = (endpointFilter, searchArgs) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_HISTORIES_SIGN_PENDING'
            })
            const username = localStorage.getItem('user');
            const res = await axios.get(`/cases/${username}/histories/${endpointFilter}${searchArgs}&user_no=${JSON.parse(localStorage.getItem("u_data")).user_no}`);
            dispatch({
                type: 'GET_HISTORIES_SIGN_DONE',
                payload: res.data
            })

        } catch (error) {
            dispatch({
                type: 'GET_HISTORIES_SIGN_ERROR',
                payload: error
            })
        }
    }
}