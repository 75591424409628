import moment from "moment";
import classnames from "classnames";
import signIcon from "../../../assets/sign.svg";
import SignatureCanvas from "react-signature-canvas";

export const defaultColumn = (loading, fileDownload) => [
    { Header: "Document", accessor: "details" },
    {
        Header: "Created Date",
        accessor: (d) => {
            return moment(d.posted_date_time).format("DD-MM-YYYY hh:mm a");
        },
    },
    {
        Header: "Signed Date",
        accessor: (d) => {
            let clientsRow = ""
            for (let i = 1; i <= d.client_count; i++) {
                const signedUser = d.signatures.filter(item => (item.signed_by === i))
                clientsRow = <div>{clientsRow} <div className={classnames({
                    "not-signed": signedUser.length <= 0,
                    "signed": signedUser.length > 0,
                })}>Client {i}: {signedUser.length > 0 ? moment(signedUser[0].created_at).format("DD-MM-YYYY hh:mm a") : "Not Signed"} </div></div>
            }

            return clientsRow
        },
    },
    {
        Header: "Sign / Download",
        accessor: (d) => {
            const userNo = JSON.parse(localStorage.getItem("u_data")).user_no
            let signedUser = [];

            for (let i = 1; i <= d.client_count; i++) {
                signedUser = d.signatures.filter(item => (item.signed_by === i))
            }

            if (userNo === 1) {
                if (d.signatures.length <= 0) {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `/main/task-sign/${d.id}?active=1`;
                            }}
                        ><img src={signIcon} alt="sign icon" /> Sign</button>
                    )
                } else {
                    if (d.signatures[0].pdf_ready) {
                        return <button disabled={loading} onClick={() => fileDownload(d.id, d.signatures[0].download_code, d.con_case_ref)}>{
                            loading ? "Downloading..." : "Download"
                        }</button>
                    } else {
                        return <span className="pdf-progress">PDF in progress</span>
                    }
                }
            }
            if (userNo === 2) {
                if (d.signatures.length === 1) {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `/main/task-sign/${d.id}?active=1`;
                            }}
                        ><img src={signIcon} alt="sign icon" /> Sign</button>
                    )
                }
                if (d.signatures.length > 1) {
                    if (d.signatures[1].pdf_ready) {
                        return <button disabled={loading} onClick={() => fileDownload(d.id, d.signatures[0].download_code, d.con_case_ref)}>
                            {
                                loading ? "Downloading..." : "Download"
                            }
                        </button>
                    } else {
                        return <span className="pdf-progress">PDF in progress</span>
                    }
                }
            }
            if (userNo === 3) {
                if (d.signatures.length === 2) {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `/main/task-sign/${d.id}?active=1`;
                            }}
                        ><img src={signIcon} alt="sign icon" /> Sign</button>
                    )
                }
                if (d.signatures.length > 2) {
                    if (d.signatures[2].pdf_ready) {
                        return <button disabled={loading} onClick={() => fileDownload(d.id, d.signatures[0].download_code, d.con_case_ref)}>
                            {
                                loading ? "Downloading..." : "Download"
                            }
                        </button>
                    } else {
                        return <span className="pdf-progress">PDF in progress</span>
                    }
                }
            }

            if (userNo === 4) {
                if (d.signatures.length === 3) {
                    return (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `/main/task-sign/${d.id}?active=1`;
                            }}
                        ><img src={signIcon} alt="sign icon" /> Sign</button>
                    )
                }
                if (d.signatures.length > 3) {
                    if (d.signatures[3].pdf_ready) {
                        return <button disabled={loading} onClick={() => fileDownload(d.id, d.signatures[0].download_code, d.con_case_ref)}>
                            {
                                loading ? "Downloading..." : "Download"
                            }
                        </button>
                    } else {
                        return <span className="pdf-progress">PDF in progress</span>
                    }
                }
            }

        },
    },
    { Header: "Internal Code", accessor: "action_code" }
]