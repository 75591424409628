const initialState = {
    fetching: false,
    fetched: true,
    data: {},
    error: {}
}
export const loggedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGGED_USER_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'LOGGED_USER_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload
            }
        case 'LOGGED_USER_ERROR':
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}