import "./App.scss";
import Header from "./components/header/header.component";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useState } from "react";

import closeBtn from "./assets/mobil-menu-close.svg";
import { useEffect } from "react";
import { setThemeColors } from "./utilities/changeTheme";
import Login from "./components/login/login.component";
import { MainView } from "./components/main-view/main-view.component";
import SideMenu from "./components/side-menu/side-menu.component";
import { IsLoggedIn } from "./redux/actions/login/isLoggedIn";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2'

function App () {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch()
  const setThemeFromStorage = () => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "light");
      return "light";
    } else {
      return localStorage.getItem("theme");
    }
  };
  useEffect(() => {
    if (!auth.done) {
      dispatch(IsLoggedIn());
    }
  }, [auth.done])
  const [theme, setTheme] = useState(setThemeFromStorage());
  const [menuOpen, setMenuOpen] = useState(false);
  /* const [userName, setUserName] = useState(null); */

  const openMenu = () => {
    if (menuOpen === false) {
      setMenuOpen(true);
      document.getElementById("body-content").classList.add("menuOpen");
    }
  };
  const closeMenu = () => {
    if (menuOpen === true) {
      setMenuOpen(false);
      document.getElementById("body-content").classList.remove("menuOpen");
    }
  };
  const loginControl = () => {
    let url = window.location.href.split('/')
    if (window.location.href.split('/')[url.length - 1] != "login") {
      if (!localStorage.getItem("u_data")) {
        Swal.fire({
          title: 'Error',
          text: 'Your session has timed out. Please login again.',
          icon: 'warning',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.value) {
            window.location.href = "https://client.avrillo.co.uk"
          }
        })

      }
    }
  }
  var session_token = localStorage.getItem("token");
  useEffect(() => {
    setThemeColors(theme)
    setInterval(function () {
      loginControl()
    }, 10000)


  }, []);
  return (
    <div className={`App ${theme}`}>
      <Router>
        <Route
          exact
          path="/"
          component={() => (
            <Redirect to="/login" />
          )}
        />
        <Route
          exact
           path="/login"
          component={() => (
           <Login /* userName={userName} */ login /* setUserName={setUserName} */ />
          )}
        />

        <Route
          path="/main"
          render={() => 
            session_token ? (
              <div>
                <div
                  id="body-content"
                  className={
                    menuOpen === true ? "body-content active" : "body-content"
                  }
                >
                  <div
                    className={menuOpen === true ? "overlay active" : "overlay"}
                  ></div>
                  <Header
                    openMenu={openMenu}
                    theme={theme}
                    setThemeColors={setThemeColors}
                    setTheme={setTheme}
                  ></Header>

                  <MainView /* userName={userName} */ />
                </div>
                <SideMenu
                  menuOpen={menuOpen}
                  closeMenu={closeMenu}
                  closeBtn={closeBtn}
                  setTheme={setTheme}
                  setThemeColors={setThemeColors}
                  theme={theme}
                />
              </div>
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            )
          }
        />
      </Router>
    </div>
  );
}
export default App;
