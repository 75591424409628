


const InitialState = {
    checkedItems:[],
    totalPrice:0
}




export const Payment = (state = InitialState, action) => {
    switch (action.type) {
        case "addCheckedItems":
            return {
                ...state,
                checkedItems:[...state.checkedItems,{...action.payload}],
                totalPrice:(+state.totalPrice) + +action.payload.price

            }
        case "removeCheckedItems":
            
            return{
                ...state,
                checkedItems:state.checkedItems.filter(item=>item.order_id !== action.payload.order_id),
                totalPrice:+state.totalPrice - +action.payload.price
            }
        case "defaultCheck":
            return{
                ...state,
                checkedItems:[...state.checkedItems,{...action.payload}],
                totalPrice:(+state.totalPrice) + +action.payload.price
            }
            case "resetPrice":
                return{
                    ...state,
                    totalPrice:0
                }

        default:
            return state
    }
}