const initialState = {
    fetching: false,
    fetched: false,
    historyData: [],
    meta: {},
    error: false
}
export const getRotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROTS_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'GET_ROTS_DONE':
            return {
                ...state,
                fetching: false,
                fetched: true,
                historyData: action.payload.data,
                meta: action.payload.meta
            }
        case 'GET_ROTS_ERROR':
            return {
                ...state,
                fetched: false,
                error: true
            }
        default:
            return state;
    }
}