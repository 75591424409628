import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
const RotOverview = (props) => {
  const dispatch = useDispatch();
  const Styles = styled.div`
  table {
    width: calc(100% - 40px);
    border-spacing: 0;
    border: 1px solid var(--table-border-color);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid var(--table-border-color);
      color: var(--font-color-active);
      font-weight: 300;
      :last-child {
        border-right: 0;
      }
    }
    button {
      width: 150px;
      background: #7fad40;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 5px 40px 5px 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--light-background-color);
      transition: background-color 0.2s;
    }
    .row:nth-of-type(even) {
      background-color: var(--collapsible-trigger-color);
      transition: background-color 0.2s;
    }
    &.stickyTable {
      margin: 20px;
      .row:nth-of-type(odd) {
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--light-background-color);
        }
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--collapsible-trigger-color);
        }
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background: var(--light-background-color);
      }
    }
    .pagination {
      display: inline;
    }
  }
  .not-result{
    text-align: center;
    width: 100%;
    border-top: 1px solid #dedede;
    padding: 35px 0
  }
  .play-video{
    float: left;
    cursor: pointer
  }
  .play-video img{
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    width: 270px;
  }
  .play-video span{
    display: block;
    padding: 0 10px 10px 10px;
  }
  .play-video:hover img{
    box-shadow: 0 2px 12px rgba(0,0,0,0.3)
  }
  .play-video:hover span{
    color:#7fad40
  }
  .hr-style{
    border-color:#dedede !important;
    background:transparent;
    opacity: 0.2
  }
  .rot-box .menu-link{
    width: calc(100% / 4 - 70px);
    padding: 25px;
    display: block;
    float: left;
    text-align: center;
    background:#dedede;
    border-radius: 8px;
    margin: 0 10px 10px;
    font-size:14px;
  }
 
  .rot-box:hover .menu-link{
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background:#7fad40;
  }
  .rot-box:hover .menu-link{
    color:#fff
  }
`;
  // Render the UI for your table
  return (
    <div>
      <Styles>
          <div className="rt-table" style={{ position: "relative" }}>
            <div className="heading-container">
              <span className="heading">
                Report on Title
              </span>
            </div>
           
            <hr className="hr-style" />
            <div className="rot-box">
            <Link to="/main/report-on-title/print-and-sign-ww" className="menu-link" >
                <b>Part A:</b> Print and Sign without Witness
              </Link>
            </div>
            <div className="rot-box">
            <Link to="/main/report-on-title/print-and-sign-w" className="menu-link" >
                <b>Part B:</b> Print and Sign with Witness
              </Link>
            </div>
            <div className="rot-box">
            <Link to="/main/report-on-title/esign-only/1" className="menu-link" >
                <b>Part C:</b> Don’t Print, e-Sign Only
              </Link>
            </div>
            <div className="rot-box">
            <Link to="/main/report-on-title/read-before/1" className="menu-link" >
            <b>Part D:</b> Title Docs to Read Before You Sign
              </Link>
            </div>
          </div>

        </Styles>
    </div>
  );
};

export default RotOverview