import axios from "../../../helpers/axios"

export const getPurchaseStepsAction = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'GET_PURCHASE_STEPS_PENDING'
            })
            const res = await axios.get(`/cases/${localStorage.getItem("user")}/purchase-steps`)
            dispatch({
                type: 'GET_PURCHASE_STEPS_DONE',
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: 'GET_PURCHASE_STEPS_ERROR',
                payload: error
            })
        }
    }
}