import axios from "../../../helpers/axios"

export const loginAction = (data = {}) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'LOGIN_USER_PENDING'
            })
            const res = await axios({
                method: 'POST',
                url: '/clients/oauth/token',
                data: { ...data }
            })
            if (res.status === 200) {
                const { access_token, user } = res.data;
                const caseKey = data.username
                localStorage.setItem("token", access_token);
                localStorage.setItem('user', caseKey);
                localStorage.setItem('u_data', JSON.stringify(user));
                dispatch({
                    type: 'LOGIN_USER_DONE',
                    payload: { access_token, user: caseKey }
                })
            }
        } catch (e) {
            dispatch({
                type: 'LOGIN_USER_ERROR',
                payload: e
            })
        }
    }
}