const initialState = {
    data: {},
    fetched: false,
    error: false
}
export const getRotReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROT_DONE':
            return {
                ...state,
                fetched: true,
                data: action.payload
            }
        case 'GET_ROT_ERROR':
            return {
                ...state,
                error: true
            }
        default:
            return state;
    }
}