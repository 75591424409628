const initialState = {
    fetching: false,
    done: false,
    user: null,
    token: null,
    error: false,
}
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER_PENDING':
            return {
                ...state,
                fetching: true
            }
        case 'LOGIN_USER_DONE':
            return {
                ...state,
                fetching: false,
                done: true,
                user: action.payload.user,
                token: action.payload.access_token
            }
        case 'LOGIN_USER_ERROR':
            return {
                ...state,
                fetching: false,
                error: true,
            }
        case 'LOGOUT_DONE':
            return {
                ...state,
                fetching: false,
                done: false,
                user: null,
                token: null,
                error: false
            }
        default:
            return state;
    }
}