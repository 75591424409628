export const setThemeColors = (theme) => {
  if (theme === "dark") {
    document.documentElement.style.setProperty(
      "--dark-background-color",
      "#2c2c2c"
    );
    document.documentElement.style.setProperty(
      "--light-background-color",
      "#424242"
    );
    document.documentElement.style.setProperty(
      "--font-color-active",
      "#f9f9f9"
    );
    document.documentElement.style.setProperty(
      "--font-color-active-2",
      "#f9f9f9"
    );
    document.documentElement.style.setProperty(
      "--font-color-passive",
      "#dbdbdb"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color",
      "#494949"
    );
    document.documentElement.style.setProperty(
      "--table-border-color",
      "#4d4d4d"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-undone",
      "#494949"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-done",
      "#494949"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-halfdone",
      "#494949"
    );
  } else if (theme === "light") {
    document.documentElement.style.setProperty(
      "--light-background-color",
      "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--dark-background-color",
      "#f5f5f5"
    );
    document.documentElement.style.setProperty(
      "--font-color-active",
      "#000000"
    );
    document.documentElement.style.setProperty(
      "--font-color-active-2",
      "#000000"
    );
    document.documentElement.style.setProperty(
      "--font-color-passive",
      "#000000"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color",
      "#494949"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color",
      "#f7faf4"
    );
    document.documentElement.style.setProperty(
      "--table-border-color",
      "#ececec"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-done",
      "#f7faf4"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-undone",
      "#fff6f6"
    );
    document.documentElement.style.setProperty(
      "--collapsible-trigger-color-halfdone",
      "#fffaf0"
    );
  }
};
