import React, { useEffect } from "react";
import { useState } from "react";
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import classNames from "classnames";

import arrowOpen from "../../assets/accordion-open.svg";
import arrowClosed from "../../assets/accordion-close.svg";
import arrowOpenDark from "../../assets/accordion-open-dark.svg";
import arrowClosedDark from "../../assets/accordion-close-dark.svg";
import completedIcon from "../../assets/complated.svg";
import notCompleteIcon from "../../assets/not-completed.svg";
import "./collapsible.style.scss";

export const CollapsibleCard = (props) => {
  const cases = useSelector((state) => state.cases);
  const [classValue, setclassValue] = useState("closed");

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const toogleclass = () => {
    if (classValue === "closed") {
      setclassValue("open");
    } else {
      setclassValue("closed");
    }
  };

  const isStepsComplete = () => {
    if (props.steps) {
      const done = props.steps.filter((step) => {
        return step.completed_at !== null;
      }).length;
      if (done === 0) {
        return "red";
      } else if (done === props.steps.length) {
        return "green";
      } else {
        return "orange";
      }
    }
  }

  const stringConverter = (description) => {
    if (description.includes("sale_ae_number")){
      return description.replace("-sale_ae_number-", cases?.data?.ae_raised_number);
    } else {
      return description
    }
  }

  return (
    <div className="collapsible">
      <button
        type="button"
        className={classNames("collapsibleTrigger", {
          red: isStepsComplete() === "red",
          green: isStepsComplete() === "green",
          orange: isStepsComplete() === "orange",
        })}
        onClick={toogleclass}
      >
        <span
          className={classNames("indexCircle", {
            redCircle: isStepsComplete() === "red",
            greenCircle: isStepsComplete() === "green",
            orangeCircle: isStepsComplete() === "orange",
          })}
        >
          {props.order}
        </span>
        <span className="processName"> {props.desc}</span>
        <span className="icons">
          {/*
          <a data-tip data-for={`tooltip-${props.order}`}>
            <img src={tooltip} alt="tooltip" />
          </a>
          */}
          <ReactTooltip
            id={`tooltip-${props.order}`}
            aria-haspopup="true"
            arrowColor="#808080"
            scrollHide={true}
            event="hover"
          >
            <p data-for={`tooltip-${props.order}`} >Tooltip</p>

          </ReactTooltip>

          {classValue === "closed" ? (
            localStorage.getItem("theme") === "dark" ? <img src={arrowOpenDark} alt="open" /> : <img src={arrowOpen} alt="open" />
          ) : (
            localStorage.getItem("theme") === "dark" ? <img src={arrowClosedDark} alt="closed" /> : <img src={arrowClosed} alt="closed" />
          )}
        </span>
      </button>
      <div id="content" className={classValue}>
        {props.steps.map((step) => {
          return (
            <div className="collapsible-step">
              <img
                className="step-icon"
                src={step.completed_at ? completedIcon : notCompleteIcon}
                alt=""
              />
              <span className="step-description">
                <span
                  className="step-number"
                  style={
                    step.completed_at
                      ? { color: "#7fad40" }
                      : { color: "#ff6361" }
                  }
                >
                  Step {step.incrementing}{" "}
                </span>

                {stringConverter(step.description)}
                {step.completed_at ? (
                  <span className="step-date">
                    {" "}
                    {moment(step.completed_at).format("D MMMM YYYY")}
                  </span>
                ) : null}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
