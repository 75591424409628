import React from "react";
import { CollapsibleCard } from "../collapsible/collapsible.component";
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

export const CollapsibleCards = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div>
      {props.stepsData ? (
        props.stepsData.to_exchange.concat(props.stepsData.after_exchange).map((item, indx) => {
          return <CollapsibleCard key={indx} desc={item.description} steps={item.steps} order={item.order} completedAt={item.completed_at} />;
        })
      ) : (
        <BounceLoader color={"#7FAD40"} loading={true} css={override} size={150} />
      )}
    </div>
  );
};
