import React, { useState, useEffect } from 'react'
import { useTable, usePagination, useSortBy, useRowState } from 'react-table'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useGlobalFilter } from 'react-table'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useSticky } from 'react-table-sticky'
import ReactPaginate from 'react-paginate'
import Pagination from 'react-router-pagination'
import Select, { createFilter, default as ReactSelect, components } from 'react-select'
import Paginate from 'react-js-pagination'
import BounceLoader from 'react-spinners/BounceLoader'
import ClipLoader from 'react-spinners/ClipLoader'
import { css } from '@emotion/core'
import dataMock from './data'
import { SearchField } from './SearchField/search-field.component'
import openIcon from '../../assets/open.svg'
import { HTTPS_URL } from '../config'
import './case-history-table.style.scss'
//import cheerio from 'cheerio'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Swal from 'sweetalert2'
import { Buffer } from 'buffer'
import { getHistoriesAction, getHistoryAction } from '../../redux/actions/history'
const Styles = styled.div`
  table {
    width: calc(100% - 40px);
    border-spacing: 0;
    border: 1px solid var(--table-border-color);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid var(--table-border-color);
      color: var(--font-color-active);
      font-weight: 300;
      :last-child {
        border-right: 0;
      }
    }
    button {
      width: 150px;
      background: #7fad40;
      color: white;
      border-radius: 5px;
      border: none;
      padding: 5px 40px 5px 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--light-background-color);
      transition: background-color 0.2s;
    }
    .row:nth-of-type(even) {
      background-color: var(--collapsible-trigger-color);
      transition: background-color 0.2s;
    }
    &.stickyTable {
      margin: 20px;
      .row:nth-of-type(odd) {
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--light-background-color);
        }
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
        td:first-of-type {
          background: var(--collapsible-trigger-color);
        }
      }
      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }
      .body {
        position: relative;
        z-index: 0;
      }
      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
        background: var(--light-background-color);
      }
    }
    .pagination {
      display: inline;
    }
  }
`
const Option = (props) => {
  return (
    <div>
      <components.Option {...props} className={props.isSelected ? 'selectedOption' : 'optionItems'}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const formats = [
  { value: 'pdf', label: 'PDF' },
  { value: 'html', label: 'HTML' },
  { value: 'doc', label: 'DOC' },
  { value: 'txt', label: 'TXT' },
  { value: 'excel', label: 'Excel' },
  { value: 'email', label: 'Email' },
  { value: 'images', label: 'Images' },
  { value: 'videos', label: 'Videos' }
]

export const CaseHistory = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [filterFormats, setFilterFormats] = useState([
    formats[0],
    formats[1],
    formats[2],
    formats[5]
  ])
  const histories = useSelector((state) => state.histories)
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `
  const pageUrl = () => {
      let url = `${window.location.origin}/main/case-history/1`
      return url
  }
  const createSearchUrl = (type, inPage = 0) => {
    let url = ''
    if (type === 'query') {
      url = `?page=${props.match.params.page}`
    } else if (type === 'url') {
      url = `${inPage}?`
    } else if (type === 'format') {
      url = `?page=${props.match.params.page}&format=` + inPage.join(',')
    }
    if (type === 'format') {
      dispatch(getHistoriesAction(url))
    }

    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split('&')
    locationSearch.forEach((item) => {
      const keyValue = item.split('=')
      url = `${url}&${keyValue[0]}=${keyValue[1]}`
    })
    return url
  }

  const getSearchInitValue = (key) => {
    let inValue = ''
    const locationSearch = props.location.search.substr(2, props.location.search.lenght).split('&')
    locationSearch.forEach((item) => {
      const keyValue = item.split('=')
      if (keyValue[0] === key) {
        inValue = keyValue[1]
      }
    })
    return inValue
  }

  const downloadDocument = (id) => {
    setLoading(true)
    const username = localStorage.getItem('user')
    axios(`${HTTPS_URL}/cases/${username}/histories/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(async (response) => {
      let str = response.data.file
      let buff = new Buffer(str, 'base64')
      let base64ToStringNew = buff
      const url = window.URL.createObjectURL(new Blob([base64ToStringNew]))
      const link = document.createElement('a')
      const extension = response.data.file_format
      if (response.data.file === '') {
        Swal.fire({
          title: 'Error',
          text: 'File not found!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        setLoading(false)
        return false
      }
      link.href = url
      setLoading(false)
      if (extension === 'html') {
        /*const scrapingRes = await axios.get(url)
        const $ = cheerio.load(scrapingRes.data)
        const scrapedHtml = $.html()
        link.setAttribute('target', '_blank')
        document.getElementById('iframe').innerHTML = `${scrapedHtml}`
        var modal = document.getElementById('letterModal')
        modal.style.display = 'block'
        var span = document.getElementsByClassName('close')[0]
        span.onclick = function () {
          modal.style.display = 'none's
        }
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = 'none'
          }
        }*/
      } else {
        if (extension === 'MAIL') {
          extension = 'msg'
        }
        link.setAttribute('download', `${id}${extension}`)
        document.body.appendChild(link)
        link.click()
      }
    })
  }
  useEffect(() => {
    if (!histories.fetched) {
      if (localStorage.getItem('formatFilter') != null) {
        let filters = JSON.parse(localStorage.getItem('formatFilter'))
        setFilterFormats(filters)
        getFilter(filters)
      } else {
        dispatch(getHistoriesAction(createSearchUrl('query')))
      }
    }
  }, [histories.fetched, dispatch])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'posted_date_time',
        Cell: (cell) => {
          return (
            <div>{`${moment(cell.row.values.posted_date_time).format('DD/MM/YYYY')} - ${moment(
              cell.row.values.posted_date_time
            ).format('HH:mm')}`}</div>
          )
        }
      },
      { Header: 'Details', accessor: 'details' },
      { Header: 'Internal Code', accessor: 'action_code' },
      {
        Header: 'View',
        accessor: 'id',
        Cell: ({ cell }) => (
          <a href={cell.row.values.document_name} download>
            <button
              value={cell.row.values}
              onClick={() => {
                downloadDocument(cell.row.values.id, cell.row.values.document_extension)
              }}
            >
              <img src={openIcon} alt="open icon" /> View
            </button>
          </a>
        )
      },
      {
        Header: 'Format',
        accessor: 'document_extension',
        Cell: (cell) => {
          if (cell.row.values.document_extension === 'MAIL') {
            return <div>E-Mail</div>
          }
          return <div>{cell.row.values.document_extension}</div>
        }
      }
    ],
    []
  )

  function getFilter(filter, reload = false) {
    const filter_formats = []
    filter.map((d) => {
      filter_formats.push(d.value)
    })
    if (filter_formats.length > 0) {
      createSearchUrl('format', filter_formats)
    }
    if (props.match.params.page != 1 && reload) {
      props.match.params.page = 1
      location.href = pageUrl()
    }
    localStorage.setItem('formatFilter', JSON.stringify(filter))
    setFilterFormats(filter)
  }

  const tableDAta = useTable(
    {
      columns,
      data: histories.fetched === true ? histories.historyData : dataMock,
      initialState: { ...histories.meta }
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    useSticky,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    data,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageSize,
    pageOptions,
    state,
    setGlobalFilter
    // totalRecords,
  } = tableDAta
  // Render the UI for your table
  return (
    <div>
      {histories.fetching ? (
        <BounceLoader color={'#7FAD40'} loading={true} css={override} size={150} />
      ) : null}
      {histories.fetched === true && histories.fetching === false ? (
        <div>
          <div id="letterModal" className="letterModal">
            <div className="letterModalContent">
              <span className="close">&times;</span>
              <div id="iframe" style={{ overflow: 'auto' }}></div>
            </div>
          </div>
          {loading === true ? (
            <div className="wait-content" id="wait-content">
              <div>
                <ClipLoader color={'#04ff00'} loading={true} size={80} />
              </div>
            </div>
          ) : null}

          <Styles>
            <div className="rt-table" style={{ position: 'relative' }}>
              <div className="heading-container">
                <span className="heading">Case History</span>
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 40 }}>
                <div className="col-4">
                  <SearchField
                    page={props.match.params.page}
                    searchText={getSearchInitValue('search')}
                    searchStartDate={getSearchInitValue('start_date')}
                    searchEndDate={getSearchInitValue('end_date')}
                  />
                </div>
                <div className="col-4 pt-label col-xs-12 col-sm-12 filter-select">
                  <ReactSelect
                    options={formats}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option
                    }}
                    isClearable={false}
                    onChange={(e) => getFilter(e, true)}
                    placeholder="Please select formats"
                    defaultValue={filterFormats}
                  />
                </div>
              </div>
              <table {...getTableProps()} className="table stickyTable">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Styles>
          <div className="bottom-controls">
            <span className="records-info">
              Showing {histories.meta.from}-{histories.meta.to + 1} of {histories.meta.total}{' '}
              entries
            </span>
            <div className="react-paginate">
              <Paginate
                onChange={(e) => {
                  props.history.push(createSearchUrl('url', e.valueOf()))
                  window.location.reload()
                }}
                prevPageText={'⟨'}
                firstPageText={'«'}
                lastPageText={'»'}
                nextPageText={'⟩'}
                itemsCountPerPage={histories.meta.per_page}
                itemClass={'pagination'}
                activePage={parseInt(props.match.params.page)}
                getPageUrl={(e) => createSearchUrl('url', e.valueOf())}
                totalItemsCount={histories.meta.total}
                pageRangeDisplayed={5}
                linkClass="linkClass"
                activeLinkClass="activeLinkClass"
              />
            </div>
            <div className="buffer"></div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
