import { combineReducers } from "redux"
import { loggedUserReducer } from "./Auth/loggedUserReducer"
import { loginReducer } from "./Auth/loginReducer"
import { getHistoriesReducer } from "./history/getHistoriesReducer"
import { getHistoryReducer } from "./history/getHistoryReducer"
import { getRotsReducer } from "./rot/getRotsReducer"
import { getRotReducer } from "./rot/getRotReducer"
import { getFlsReducer } from "./fl/getFlsReducer"
import { getFlReducer } from "./fl/getFlReducer"
import { getCasesReducer } from "./overview/getCasesReducer"
import { getPurchaseSteps } from "./overview/getPurchaseStepReducer"
import { getSaleStepReducer } from "./overview/getSaleStepsReducer"
import { getHistoriesSignReducer } from "./task/getHistoriesSignReducer"
import { getSignDataReducer } from "./task/getSignDataReducer"
import { getRotSignReducer } from "./rotEs/getRotSignReducer"
import { getRotSignDataReducer } from "./rotEs/getRotSignDataReducer"
import { getFlSignReducer } from "./flEs/getFlSignReducer"
import { getFlSignDataReducer } from "./flEs/getFlSignDataReducer"
import tour from "./tour"
import { taskCountReducer } from "./task/taskCountReducer"
import { Payment } from "./payment"

export const rootReducer = combineReducers({
    auth: loginReducer,
    histories: getHistoriesReducer,
    history: getHistoryReducer,
    rots: getRotsReducer,
    rot: getRotReducer,
    fls: getFlsReducer,
    fl: getFlReducer,
    historiesSign: getHistoriesSignReducer,
    RotSign: getRotSignReducer,
    FlSign: getFlSignReducer,
    cases: getCasesReducer,
    saleSteps: getSaleStepReducer,
    purchaseSteps: getPurchaseSteps,
    signData: getSignDataReducer,
    RotsignData: getRotSignDataReducer,
    FlsignData: getFlSignDataReducer,
    loggedUser: loggedUserReducer,
    tourReducer: tour,
    taskCount: taskCountReducer,
    payment: Payment
})