import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, usePagination, useSortBy, useRowState } from 'react-table'
import { useGlobalFilter } from 'react-table'
import { useSticky } from 'react-table-sticky'
import styled from 'styled-components'
import { HTTPS_URL } from '../config'
import Swal from 'sweetalert2'
import ClipLoader from 'react-spinners/ClipLoader'
//import cheerio from 'cheerio'
import { Link } from 'react-router-dom'
import no_result_found from '../../assets/no_result_found.png'
import { getFlsAction, getFlAction } from '../../redux/actions/fl'
import openIcon from '../../assets/open.svg'
import axios from 'axios'
import { Buffer } from 'buffer'
import moment from 'moment'
import { defaultColumn } from './pages/columns/default'
const FlOverview = (props) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const fls = useSelector((state) => state.fls)
  const [flSign, setFlSign] = useState([])
  useEffect(() => {
    if (props.document_metter == 'PURCHASE') {
      setFlSign([])
      dispatch(getFlsAction(props.document_metter, 'all'))
    }
  }, [props])
  useEffect(() => {
    if (props.document_metter == 'SALE') {
      setFlSign([])
      dispatch(getFlsAction(props.document_metter, 'all'))
    }
  }, [props])
  useEffect(() => {
    if (fls.fetched) {
      setFlSign(fls.data.esign)
      setTimeout(function () {
        setLoading(false)
      }, 2000)
    }
  }, [fls])
  const fileDownload = async (historyId, downloadCode, ref) => {
    setLoading(true)
    const username = localStorage.getItem('user')
    axios(
      `${HTTPS_URL}/cases/${ref}/histories/e-sign/${historyId}/download?download_code=${downloadCode}`,
      {
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    ).then(async (response) => {
      console.log(response.data)
      setLoading(false)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${historyId}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
  }
  const columns = React.useMemo(
    () => defaultColumn(loading, fileDownload, props.document_metter),
    []
  )

  const tableDAta = useTable(
    {
      columns,
      data: flSign,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
    useSticky,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    data,
    gotoPage,
    setPageSize,
    pageSize,
    state,
    setGlobalFilter
    // totalRecords,
  } = tableDAta
  const Styles = styled.div`
    table {
      width: calc(100% - 40px);
      border-spacing: 0;
      border: 1px solid var(--table-border-color);
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border: 1px solid var(--table-border-color);
        color: var(--font-color-active);
        font-weight: 300;
        :last-child {
          border-right: 0;
        }
      }
      button {
        width: 150px;
        background: #7fad40;
        color: white;
        border-radius: 5px;
        border: none;
        padding: 5px 20px 5px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .row:nth-of-type(odd) {
        background-color: var(--light-background-color);
        transition: background-color 0.2s;
      }
      .row:nth-of-type(even) {
        background-color: var(--collapsible-trigger-color);
        transition: background-color 0.2s;
      }
      &.stickyTable {
        margin: 20px;
        .row:nth-of-type(odd) {
          transition: background-color 0.2s;
          td:first-of-type {
            background: var(--light-background-color);
          }
        }
        .row:nth-of-type(even) {
          background-color: var(--collapsible-trigger-color);
          transition: background-color 0.2s;
          td:first-of-type {
            background: var(--collapsible-trigger-color);
          }
        }
        .header {
          top: 0;
          box-shadow: 0px 3px 3px #ccc;
        }
        .body {
          position: relative;
          z-index: 0;
        }
        [data-sticky-last-left-td] {
          box-shadow: 2px 0px 3px #ccc;
          background: var(--light-background-color);
        }
      }
      .pagination {
        display: inline;
      }
    }
    .not-result {
      text-align: center;
      width: 100%;
      border-top: 1px solid #dedede;
      padding: 35px 0;
    }
    .play-video {
      float: left;
      cursor: pointer;
    }
    .play-video img {
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      width: 270px;
    }
    .play-video span {
      display: block;
      padding: 0 10px 10px 10px;
    }
    .play-video:hover img {
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    }
    .play-video:hover span {
      color: #7fad40;
    }
    .hr-style {
      border-color: #dedede !important;
      background: transparent;
      opacity: 0.2;
    }
    .rot-box .menu-link {
      width: calc(100% / 2 - 70px);
      padding: 25px;
      display: block;
      float: left;
      text-align: center;
      background: #dedede;
      border-radius: 8px;
      margin: 0 10px 10px;
      font-size: 14px;
    }

    .rot-box:hover .menu-link {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background: #7fad40;
    }
    .rot-box:hover .menu-link {
      color: #fff;
    }
  `
  var document_metter = 'Sale'
  var case_uri = 'sale-docs'
  if (props.document_metter == 'PURCHASE') {
    document_metter = 'Purchase'
    var case_uri = 'purchase-docs'
  }

  const downloadDocument = (id) => {
    setLoading(true)
    const username = localStorage.getItem('user')
    axios(`${HTTPS_URL}/cases/${username}/histories/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(async (response) => {
      console.log(response)
      let str = response.data.file
      let buff = new Buffer(str, 'base64')
      let base64ToStringNew = buff
      const url = window.URL.createObjectURL(new Blob([base64ToStringNew]))
      const link = document.createElement('a')
      const extension = response.data.file_format
      if (response.data.file === '') {
        Swal.fire({
          title: 'Error',
          text: 'File not found!',
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
        setLoading(false)
        return false
      }
      link.href = url
      setLoading(false)
      if (extension === 'html') {
        /*const scrapingRes = await axios.get(url)
        const $ = cheerio.load(scrapingRes.data)
        const scrapedHtml = $.html()
        link.setAttribute('target', '_blank')
        document.getElementById('iframe').innerHTML = `${scrapedHtml}`
        var modal = document.getElementById('letterModal')
        modal.style.display = 'block'
        var span = document.getElementsByClassName('close')[0]
        span.onclick = function () {
          modal.style.display = 'none'
        }
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = 'none'
          }
        }*/
      } else {
        if (extension === 'MAIL') {
          extension = 'msg'
        }
        link.setAttribute('download', `${id}${extension}`)
        document.body.appendChild(link)
        link.click()
      }
    })
  }

  const renderOnlineQuestionnaireForm = () => {
    if (fls.fetched && fls.data.aml.length !== 0) {
      return (
        <div
          className="rt-table"
          style={{ position: 'relative', marginBottom: 30, borderRadius: 10 }}
        >
          <div className="heading-container">
            <span className="heading">
              Section {props.document_metter === 'PURCHASE' ? 'A' : 'C'}. Online questionnaire form
            </span>
          </div>

          <table className="table stickyTable">
            <thead>
              <th className="text-left">Document</th>
              <th width="200">Created Date</th>
              <th width="150">Fill</th>
              {/* <th width="150">Download</th> */}
            </thead>
            <tbody>
              {fls.fetched && fls.data.aml && (
                <>
                  {fls.data.aml.map((form) => (
                    <tr>
                      <td>{form.description}</td>
                      <td>{moment(form.created_at).format('DD-MM-YYYY HH:mm')}</td>
                      <td>
                        {form.submit ? (
                          <>
                            <div className="text-success text-center">Submitted</div>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={(e) => {
                                e.preventDefault()
                                window.open(form.link, '_blank')
                              }}
                            >
                              <img src={openIcon} alt="open icon" /> Open
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      )
    }
    return null
  }
  return (
    <div>
      {loading === true ? (
        <div className="wait-content" id="wait-content">
          <div>
            <ClipLoader color={'#04ff00'} loading={true} size={80} />
          </div>
        </div>
      ) : null}
      <Styles>
        <div
          className="rt-table"
          style={{ position: 'relative', marginBottom: 30, borderRadius: 10 }}
        >
          <div className="heading-container">
            <span className="heading">First Letters of {document_metter} </span>
          </div>
          <hr className="hr-style" />
          {fls.fetched && fls.data.wet_sign.length !== 0 && document_metter !== 'Purchase' && (
            <>
              <div className="heading-container">
                <span className="heading">Section A. Print, sign in pen and post back</span>
              </div>

              <table className="table stickyTable">
                <thead>
                  <th className="text-left">Document</th>
                  <th width="200">Created Date</th>
                  <th width="150">Download</th>
                </thead>
                <tbody>
                  {fls.fetched && fls.data.wet_sign.length > 0 && (
                    <>
                      {fls.data.wet_sign.map((form) => (
                        <tr>
                          <td>{form.details}</td>
                          <td>{moment(form.posted_date_time).format('DD-MM-YYYY HH:mm')}</td>
                          <td>
                            {form.submit ? (
                              <>
                                <div className="text-success text-center">Submitted</div>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    downloadDocument(form.id)
                                  }}
                                >
                                  <img src={openIcon} alt="open icon" /> Download
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {fls.fetched && fls.data.wet_sign.length === 0 && (
                    <tr>
                      <td colspan="6">
                        <div class="not-result">
                          <img src={no_result_found} className="icon" height="150" />
                          <h3>Not Result</h3>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
        {props.document_metter === 'PURCHASE' && renderOnlineQuestionnaireForm()}
        {fls.fetched && fls.data.esign.length !== 0 && (
          <div
            className="rt-table"
            style={{ position: 'relative', marginBottom: 30, borderRadius: 10 }}
          >
            <div className="heading-container">
              <span className="heading">Section B. E-sign online portal documents</span>
            </div>

            <table {...getTableProps()} className="table stickyTable">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        width={
                          column.render('Header') == 'Sign'
                            ? '150'
                            : column.render('Header') == 'Signed Date'
                            ? '200'
                            : column.render('Header') == 'Created Date'
                            ? '200'
                            : ''
                        }
                        className={column.render('Header') == 'Document' ? 'text-left' : ''}
                      >
                        <b>{column.render('Header')}</b>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr className="row" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {props.document_metter === 'SALE' && renderOnlineQuestionnaireForm()}
        {fls.fetched && fls.data.protocol.length !== 0 && (
          <div
            className="rt-table"
            style={{ position: 'relative', marginBottom: 30, borderRadius: 10 }}
          >
            <div className="heading-container">
              <span className="heading">Section D. Online protocol forms</span>
            </div>

            <table className="table stickyTable">
              <thead>
                <th className="text-left">Document</th>
                <th width="200">Created Date</th>
                <th width="150">Fill</th>
                {/* <th width="150">Download</th> */}
              </thead>
              <tbody>
                {fls.fetched && fls.data.protocol && (
                  <>
                    {fls.data.protocol.map((form) => (
                      <tr>
                        <td>{form.description}</td>
                        <td>{moment(form.created_at).format('DD-MM-YYYY HH:mm')}</td>
                        <td className="text-center">
                          {form.submit ? (
                            <>
                              <div className="text-success">Submitted</div>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={(e) => {
                                  e.preventDefault()
                                  window.open(form.link, '_blank')
                                }}
                              >
                                <img src={openIcon} alt="open icon" /> Open
                              </button>
                            </>
                          )}
                        </td>
                        {/*
                 <td>{ form.download ? (
                   <>
                   <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    form.link, "_blank");
                }}
              >
                <img src={openIcon} alt="open icon" /> Download
              </button>
                   </>
                 ) : (
                   <>
                   <div className="text-danger text-center">Not Ready</div>
                   </>
                 ) }</td>
                 */}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}
        {fls.fetched &&
          fls.data.wet_sign.length === 0 &&
          fls.data.esign.length === 0 &&
          fls.data.aml.length === 0 &&
          fls.data.protocol.length === 0 && (
            <div
              className="rt-table"
              style={{ position: 'relative', borderRadius: 0, marginTop: -40 }}
            >
              <div class="not-result" style={{ border: 0 }}>
                <img src={no_result_found} className="icon" height="150" />
                <h3>Not Result</h3>
              </div>
            </div>
          )}
      </Styles>
    </div>
  )
}

export default FlOverview
